import axiosClient from "@/config/AxiosClient"
import AuthToken from "@/config/AuthToken"

export const IntegrationServices = {
  Show(currentProcessId) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    return axiosClient.get(`/api/2.0/project/connection/${currentProcessId}`, config)
  },
  ProjectInit(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    return axiosClient.post(`/api/2.0/integrations`, payload, config)
  },
  requestList(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    return axiosClient.post(`/api/2.0/integrations/mailup/lists`, payload, config)
  },
  requestGroups(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    const { id } = payload
    return axiosClient.post(`/api/2.0/integrations/mailup/lists/${id}/groups`, payload, config)
  },
  validateTo(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    return axiosClient.post(`/api/2.0/integrations/check-services/to`, payload, config)
  },
  validateFrom(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    return axiosClient.post(`/api/2.0/integrations/check-services/from`, payload, config)
  },
  Config(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    const { id } = payload
    const { data } = payload
    return axiosClient.put(`/api/2.0/integrations/${id}/config`, data, config)
  },
  autoMapper(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    const { id } = payload
    const { object } = payload
    return axiosClient.put(`/api/2.0/integrations/${id}/automapper`, object, config)
  },
  frequency() {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    return axiosClient.get(`/api/2.0/utilities/schedule-frequency`, config)
  }
}
