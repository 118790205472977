import axiosClient from '../config/AxiosClient'

export const AdminUserService = {
  list,
  show,
  store,
  update,
  updatePassword,
  enabled,
  remove,
}

function list (filter) {
  let accessToken = localStorage.getItem('accessToken')
  let config = {
    headers: {
      "Authorization": `Bearer ${accessToken}`
    },
    params: filter
  }

  return axiosClient.get('api/2.0/admin/users', config)
}

function show (id) {
  let accessToken = localStorage.getItem('accessToken')
  let config = {
    headers: {
      "Authorization": `Bearer ${accessToken}`
    },
  }

  return axiosClient.get(`api/2.0/admin/users/${id}`, config)
}

function store (values) {
  let accessToken = localStorage.getItem('accessToken')
  let config = {
    headers: {
      "Authorization": `Bearer ${accessToken}`
    },
  }

  return axiosClient.post('api/2.0/admin/users', values, config)
}

function update (id, values) {
  let accessToken = localStorage.getItem('accessToken')
  let config = {
    headers: {
      "Authorization": `Bearer ${accessToken}`
    },
  }

  return axiosClient.put(`api/2.0/admin/users/${id}`, values, config)
}

function updatePassword (id, values) {
  let accessToken = localStorage.getItem('accessToken')
  let config = {
    headers: {
      "Authorization": `Bearer ${accessToken}`
    },
  }

  return axiosClient.put(`api/2.0/admin/users/${id}/password`, values, config)
}

function enabled (id, is_active) {
  let accessToken = localStorage.getItem('accessToken')
  let config = {
    headers: {
      "Authorization": `Bearer ${accessToken}`
    },
  }

  let is_active_text = is_active ? "true" : "false"
  return axiosClient.put(`api/2.0/admin/users/${id}/enabled/${is_active_text}`, {}, config)
}

function remove (id) {
  let accessToken = localStorage.getItem('accessToken')
  let config = {
    headers: {
      "Authorization": `Bearer ${accessToken}`
    },
  }

  return axiosClient.delete(`api/2.0/admin/users/${id}`, config)
}

