import { AudienceServices } from "@/services/Audience"

const state = {
  projectAudience: [],
  projectAudienceLinks: [],
  projectAudienceMeta: [],
  isProjectAudienceActive: false,
  isProjectAudienceError: false,

  projectAudienceKeys: [],
  projectAudienceKeysFilter: [],

  library: [],
  libraryAdded: [],

  channels: null,

  collection: [],
  collectionLinks: [],
  collectionMeta: [],

  segmentAudience: [],
  segmentAudienceMeta: [],
  isSegmentAudienceActive: false,
  isSegmentAudienceError: false,

  segmentProfile: [],
  segmentProfileLinks: [],
  segmentProfileMeta: [],
  isSegmentProfileError: null,
  isSegmentProfileActive: null,
  isProfileData: [],

  loading: false,
  simulationLoading: false,
  data: {
    count: 0,
    data: []
  },
  updatingSegment: null,
  typeSelected: "menu"
}

const mutations = {
  loading(state) {
    state.loading = true
  },
  loadingFalse(state) {
    state.loading = false
  },
  getAudience(state, audience) {
    state.projectAudience = audience.data
    state.projectAudienceLinks = audience.links
    state.projectAudienceMeta = audience.meta
    state.isProjectAudienceActive = true
    state.isProjectAudienceError = false
    state.loading = false
    // console.log(state)
  },
  getAudienceKeys(state, keys) {
    state.projectAudienceKeys = keys
  },
  getAudienceKeysFilter(state, keys) {
    state.projectAudienceKeysFilter = keys
  },
  getSegments(state, segments) {
    state.segmentAudience = segments.data
    state.segmentAudienceMeta = segments.meta
    state.isSegmentAudienceActive = true
    state.isSegmentAudienceError = false
    state.loading = false
  },
  getSegmentProfile(state, profiles) {
    state.segmentProfile = profiles.data
    state.segmentProfileLinks = profiles.links
    state.segmentProfileMeta = profiles.meta
    state.isSegmentProfileError = false
    state.isSegmentProfileActive = true
    state.loading = false
    // console.log(state)
  },
  simulationLoading(state) {
    if (state.simulationLoading) {
      state.simulationLoading = false
    } else {
      state.simulationLoading = true
    }
  },
  simulateSegment(state, data) {
    state.data.data = data.data
  },
  updatingSegment(state, item) {
    state.typeSelected = item.rules.type || "custom"
    state.updatingSegment = item
    // console.log(state)
  },
  getLibrary(state, data) {
    state.library = data.data
    // console.log(state)
  },
  getCollection(state, data) {
    state.collection = data.data
    state.collectionLinks = data.links
    state.collectionMeta = data.meta
    // console.log(state)
  },
  getChannels(state, data) {
    state.channels = data
    // console.log(state)
  },
  changeTypeSelected(state, data) {
    state.typeSelected = data
  },
  getProfileData(state, data) {
    state.isProfileData = data.data
    // console.log(state)
  },
  resetProfileData(state) {
    state.isProfileData = []
  },
  resetTypeSelected(state) {
    state.typeSelected = "menu"
  },
  resetChannels(state) {
    state.channels = null
  },
  resetCollection(state) {
    state.collection = []
    state.collectionLinks = []
    state.collectionMeta = []
  },
  resetUpdatingSegment(state) {
    state.updatingSegment = null
  },
  resetSimulation(state) {
    state.data.count = 0
    state.data.data = []
  },
  resetAudience(state) {
    state.projectAudience = []
    state.projectAudienceLinks = []
    state.projectAudienceMeta = []
    state.library = []
    state.isProjectAudienceActive = false
    state.isProjectAudienceError = false
  },
  resetSegments(state) {
    state.segmentAudience = []
    state.isSegmentAudienceActive = false
    state.isSegmentAudienceError = false
    state.loading = false
  },
  resetSegmentProfile(state) {
    state.segmentProfile = []
    state.segmentProfileLinks = []
    state.segmentProfileMeta = []
    state.isSegmentProfileError = false
    state.isSegmentProfileActive = false
    state.loading = false
  }
}

const actions = {
  async getAudience({ commit }, payload) {
    let result = null
    commit("resetAudience")
    commit("loading")
    try {
      result = await AudienceServices.getAudience(payload)
      commit("getAudience", result.data)
    } catch (error) {
      result = error.response
    }
    return result
  },
  async storeSegment(aux, payload) {
    let result = null
    try {
      result = await AudienceServices.storeSegment(payload)
    } catch (error) {
      result = error.response
    }
    return result
  },
  async updateSegment(aux, payload) {
    let result = null
    try {
      result = await AudienceServices.updateSegment(payload)
    } catch (error) {
      result = error.response
    }
    return result
  },
  async simulateSegment({ commit }, payload) {
    commit("simulationLoading")
    commit("simulateSegment", {
      meta: {
        total: 0
      },
      data: {}
    })

    let result = null

    try {
      result = await AudienceServices.simulateSegment(payload)
      commit("simulateSegment", result.data)
    } catch (error) {
      result = error.response
      commit("simulateSegment", {
        data: {
          error: true,
          message: result.message || "Error"
        },
        meta: {
          total: 0
        }
      })
    }
    commit("simulationLoading")
    return result
  },
  resetSimulation({ commit }) {
    commit("resetSimulation")
  },
  async syncSegment(aux, payload) {
    let result = null
    try {
      result = await AudienceServices.syncSegment(payload)
    } catch (error) {
      result = error.result
    }
    return result
  },
  async getSegments({ commit }, payload) {
    let result = null
    commit("resetSegments")
    commit("loading")
    try {
      result = await AudienceServices.getSegments(payload)
      commit("getSegments", result.data)
    } catch (error) {
      commit("loadingFalse")
      result = error.response
    }
    return result
  },
  async getAudienceKeys({ commit }, currentProject) {
    let result = null
    try {
      result = await AudienceServices.getAudienceKeys(currentProject)
      let data = result.data.map((f) => {
        return { key: f, label: f }
      })
      commit("getAudienceKeys", data)
    } catch (error) {
      result = error.result
    }
    return result
  },
  async getAudienceKeysFilter({ commit }, currentProject) {
    let result = null
    try {
      result = await AudienceServices.getAudienceKeysFilter(currentProject)

      // let data = result.data.map((f) => {
      //   return { key: f, label: f }
      // })

      let data = []
      Object.keys(result.data).forEach(key => {
        data.push({
          key: result.data[key].key_filter,
          label: result.data[key].label || result.data[key].key_filter,
          type: result.data[key].type || "string"
        })
      })

      commit("getAudienceKeysFilter", data)
    } catch (error) {
      result = error.result
    }
    return result
  },
  async showSegmentProfile({ commit }, payload) {
    let result = null
    commit("resetSegmentProfile")
    commit("loading")
    try {
      result = await AudienceServices.showSegmentProfile(payload)
      commit("getSegmentProfile", result.data)
    } catch (error) {
      result = error.response
    }
    commit("loadingFalse")
    return result
  },
  async showSegmentProfileWithEmail({ commit }, payload) {
    let result = null
    commit("resetProfileData")
    try {
      result = await AudienceServices.showSegmentProfileWithEmail(payload)
      commit("getProfileData", result.data)
    } catch (error) {
      result = error.response
    }
    return result
  },
  async deleteSegment(aux, id, currentProject) {
    let result = null
    try {
      result = await AudienceServices.deleteSegment(id, currentProject)
    } catch (error) {
      result = error.response
    }
    return result
  },
  async getLibrary({ commit }, currentProject) {
    let result = null
    try {
      result = await AudienceServices.getLibrary(currentProject)
      commit("getLibrary", result.data)
    } catch (error) {
      result = error.response
    }
    return result
  },
  async storeLibrary(aux, payload) {
    let result = null
    try {
      result = await AudienceServices.storeLibrary(payload)
    } catch (error) {
      result = error.response
    }
    return result
  },
  updatingSegment({ commit }, item) {
    commit("resetUpdatingSegment")
    commit("updatingSegment", item)
  },
  resetUpdatingSegment({ commit }) {
    commit("resetUpdatingSegment")
  },
  async getCollection({ commit }, currentProject) {
    let result = null
    commit("resetCollection")
    try {
      result = await AudienceServices.getCollection(currentProject)
      commit("getCollection", result.data)
    } catch (error) {
      result = error.response
    }
    return result
  },
  async getChannels({ commit }, currentProject) {
    let result = null
    commit("resetChannels")
    try {
      result = await AudienceServices.getChannels(currentProject)
      let channel = result.data.filter((channel) => channel.name === "MailUp")
      commit("getChannels", channel[0].cache.fields)
    } catch (error) {
      result = error.response
    }
    return result
  },
  changeTypeSelected({ commit }, payload) {
    commit("changeTypeSelected", payload)
  },
  resetTypeSelected({ commit }) {
    commit("resetTypeSelected")
  }
}

export const Audience = {
  namespaced: true,
  state,
  mutations,
  actions
}
