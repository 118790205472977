import axiosClient from '../config/AxiosClient'

export const ProjectService = {
  list,
  show,
  store,
  update,
  enabled,
  remove,
  userAttach,
  userDetach
}

function list (filter) {
  let accessToken = localStorage.getItem('accessToken')
  let config = {
    headers: {
      "Authorization": `Bearer ${accessToken}`
    },
    params: filter
  }

  return axiosClient.get('api/2.0/admin/projects', config)
}

function show (id) {
  let accessToken = localStorage.getItem('accessToken')
  let config = {
    headers: {
      "Authorization": `Bearer ${accessToken}`
    }
  }

  return axiosClient.get(`api/2.0/admin/projects/${id}`, config)
}

function store (values) {
  let accessToken = localStorage.getItem('accessToken')
  let config = {
    headers: {
      "Authorization": `Bearer ${accessToken}`
    },
  }

  return axiosClient.post('api/2.0/admin/projects', values, config)
}

function update (id, values) {
  let accessToken = localStorage.getItem('accessToken')
  let config = {
    headers: {
      "Authorization": `Bearer ${accessToken}`
    },
  }
  return axiosClient.put(`api/2.0/admin/projects/${id}`, values, config)
}

function enabled (id, is_active) {
  let accessToken = localStorage.getItem('accessToken')
  let config = {
    headers: {
      "Authorization": `Bearer ${accessToken}`
    },
  }

  let is_active_text = is_active ? "true" : "false"
  return axiosClient.put(`api/2.0/admin/projects/${id}/enabled/${is_active_text}`, {}, config)
}

function remove (id) {
  let accessToken = localStorage.getItem('accessToken')
  let config = {
    headers: {
      "Authorization": `Bearer ${accessToken}`
    },
  }
  return axiosClient.delete(`api/2.0/admin/projects/${id}`, config)
}

function userAttach (id, userId, sendEmail) {
  let accessToken = localStorage.getItem('accessToken')
  let config = {
    headers: {
      "Authorization": `Bearer ${accessToken}`
    },
  }
  return axiosClient.put(`api/2.0/admin/projects/${id}/attach/${userId}`, {url: `${process.env.VUE_APP_URL}/login`, sendEmail: sendEmail}, config)
}

function userDetach (id, userId) {
  let accessToken = localStorage.getItem('accessToken')
  let config = {
    headers: {
      "Authorization": `Bearer ${accessToken}`
    },
  }
  return axiosClient.put(`api/2.0/admin/projects/${id}/detach/${userId}`, {}, config)
}

