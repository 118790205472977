import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import VueClickAway from "vue3-click-away"
import axios from "axios"
import VueAxios from "vue-axios"
import FlagIcon from "vue-flag-icon"
import moment from "moment"
import Accounting from "accounting-js"
import VueDebounce from "vue-debounce"
import JsonViewer from "vue3-json-viewer"
import "vue3-json-viewer/dist/index.css"

// Moment
import 'moment/locale/es'
import 'moment/locale/pt'
// Animate
import "animate.css"
// Swal
import VueSweetalert2 from "vue-sweetalert2"
import "sweetalert2/dist/sweetalert2.min.css"
import SwalConfig from "@/config/SweetAlert2"
// Toltip
import Tooltip from "primevue/tooltip"
// Modal
import VueFinalModal from "vue-final-modal"
// Toast
import Toast from "vue-toastification"
import { useToast } from "vue-toastification"
import "vue-toastification/dist/index.css"
import { ToastConfig } from "@/config/Toast"
// Calendar
import VCalendar from "v-calendar"
import vc_date_picker from "v-calendar"
import "v-calendar/dist/style.css"
// Primevue
import PrimeVue from "primevue/config"
import "@vueform/multiselect/themes/default.css" // select css
import "primeicons/primeicons.css"
import "primevue/resources/themes/tailwind-light/theme.css"
import "primevue/resources/primevue.min.css"
import ConfirmationService from "primevue/confirmationservice"

// Global CSS
import "@/assets/styles/index.css"
import "@/assets/styles/query-builder.css"
import "@/assets/styles/index_new.css"

// Copy to clipboard
import copyText from "@meforma/vue-copy-to-clipboard"
// Langs
import { createI18n } from "vue-i18n"
import en from "@/translations/en.json"
import es from "@/translations/es.json"
import pt from "@/translations/pt.json"

import timeago from "vue-timeago3"

const i18n = createI18n({
  messages: {
    en,
    es,
    pt
  },
  locale: "en",
  legacy: false,
  fallbackLocale: "en"
})

moment.locale("es")

const app = createApp(App)

app.use(VCalendar, vc_date_picker, {
  componentPrefix: "vc" // Use <vc-calendar /> instead of <v-calendar />
})

app.use(PrimeVue, {
  ripple: false,
  inputStyle: "outlined",
  zIndex: {
    modal: 1100,
    overlay: 1000,
    menu: 1000,
    tooltip: 1100
  }
})

app.use(ConfirmationService)
app.use(VueDebounce)
app.use(VueFinalModal())
app.use(FlagIcon)
app.use(store)
app.use(router)
app.use(VueClickAway)
app.use(i18n)
app.use(VueSweetalert2, SwalConfig)
app.use(VueAxios, axios)
app.use(Toast, ToastConfig)
app.use(JsonViewer)
app.use(copyText)
app.use(timeago)
app.directive("tooltip", Tooltip)

app.config.globalProperties.$toast = useToast()
app.config.globalProperties.$moment = moment
app.config.globalProperties.$filters = {
  number(value, precision, thousand, decimal) {
    precision = precision || 0
    thousand = thousand || "."
    decimal = decimal || ","
    return Accounting.formatNumber(value, { precision, thousand, decimal })
  },
  currency(value, symbol, precision, thousand, decimal) {
    symbol = symbol || "$"
    precision = precision || 0
    thousand = thousand || "."
    decimal = decimal || ","
    return Accounting.formatMoney(value, { symbol, precision, thousand, decimal })
  }
}

app.config.globalProperties.$verifyPermission = (allPermissions, permissionsCheck, type='every') => {
  let allPermissionsWithoutAsterik = allPermissions.filter(f => f.split('.*').length > 1).map(f => f.split('.*')[0])
  let findPermission = (permissionCheck) => {
    return allPermissions.find(permission => permission == permissionCheck) != undefined || allPermissionsWithoutAsterik.find(permission => permission.search(permissionCheck) != undefined)
  }
  return type == 'every' ? permissionsCheck.every(permissionCheck => findPermission(permissionCheck)) : permissionsCheck.some(permissionCheck => findPermission(permissionCheck))
}

app.config.globalProperties.$verifyFeature = (project, feature) => {
  if(!project.features) return false
  return project.features.find(f => f.name == feature)?.pivot?.enabled ?? false
}

router.isReady().then(() => {
  app.mount("#app")
})
