import { AdminJobIntegrationService } from "../services/AdminJobIntegration";

const state = {
    data: [],
    meta: {},
    links: {},
    authorized: true,
}

const actions = {
    async list({commit}, filter){
        try {
            let response = await AdminJobIntegrationService.list(filter);
            commit('setData', response.data);
        } catch (error) {
            if (error.toString().includes("403")) {
                commit('setAuthorized', false)
            }
        }
    },
    async refresh({commit}, filter){
        try {
            let response = await AdminJobIntegrationService.list(filter);
            commit('setData', response.data);
        } catch (error) {
            if (error.toString().includes("403")) {
                commit('setAuthorized', false)
            }
        }
        commit('requestEnd');
    },
}

const mutations = {
    setAuthorized(state, authorized){
        state.authorized = authorized;
    },
    setData(state, data){
        state.data = [...data.data];
        state.meta = {...data.meta};
        state.links = {...data.link};
    },
}

export const AdminJobIntegration = {
    namespaced: true,
    state,
    actions,
    mutations
}