export default {
  utilities: {
    roles: "api/2.0/utilities/company/roles",
    industries: "api/2.0/utilities/industries",
    countries: "api/2.0/utilities/countries",
    timezone: "api/2.0/utilities/timezone",
    currencies: "api/2.0/utilities/currencies"
  },
  auth: {
    login: "api/2.0/auth/login",
    profile: "api/2.0/auth/profile",
    forgot: "api/2.0/auth/send-reset-link",
    reset: "api/2.0/auth/reset-password"
  },
  register: {
    register: "api/2.0/register",
    confirmation: "api/2.0/auth/email/verification-notification/"
  },
  user: {
    update: "api/2.0/auth/profile",
    invitations: "api/2.0/invitations/user/%(user)s?include=project",
    acceptInvitation: "api/2.0/accept/invitation/%(invitation_id)s/%(user)s"
  },
  project: {
    store: "api/2.0/projects",
    appearance: "api/2.0/project/%(project_id)s/appearance",
    credentials: "api/2.0/project/%(project_id)s/credentials",
    fields: "api/2.0/segment/service/%(channel_id)s/fields",
    permission: "api/2.0/projects/%(project_id)s/permission",
    emptyCollection: "api/2.0/project/%(project_id)s/empty/%(collection)s"
  },
  team: {
    setRole: "api/2.0/project/%(project_id)s/setRole"
  },
  integration: {
    execute: "api/2.0/integration/%(integration_id)s/run",
    delete: "api/2.0/project/connection/%(project_connection_id)s",
    toggleActivation: "api/2.0/project/connection/%(project_connection_id)s/activateOrDeactivate",
    sync: "api/2.0/integration/%(integration_id)s/syncAt",
    logs: "api/2.0/integration/%(integration_id)s/logs"
  },
  wizard: {
    examples: "api/2.0/project/connection/examples"
  },
  template: {
    index: "api/2.0/templates/%(project_id)s",
    store: "api/2.0/projects/%(project_id)s/templates",
    show: "api/2.0/templates/%(project_id)s?filter[id][in]=%(template_id)s",
    delete: "api/2.0/templates/%(template_id)s",
    duplicate: "api/2.0/templates/%(template_id)s/duplicate",
    sync: "api/2.0/templates/%(template_id)s/sync",
    contentCustomRows: "api/2.0/projects/%(project_id)s/templates/content-custom-rows",
    groupedCategories: "api/2.0/projects/%(project_id)s/templates/groupedCategories",
    products: "api/2.0/projects/%(project_id)s/templates/productsTemplates",
    groupedSources: "api/2.0/projects/%(project_id)s/sources",
  },
  content: {
    search: "api/1.0/projects/%(project_id)s/content"
  },
  segment: {
    calculate: "api/2.0/segment/%(segment_id)s/calculate",
    segmentIntegrations: "api/2.0/segment/%(segment_id)s/integration",
    syncNow: "api/2.0/segment/integration/%(segment_integration_id)s/sync",
    changeActive: "api/2.0/segment/integration/%(segment_integration_id)s/changeActive"
  },
  tags: {
    index: "api/2.0/tags"
  }
}
