import axiosClient from "@/config/AxiosClient"
import AuthToken from "@/config/AuthToken"

export const ProjectServices = {
  getProject(id) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    return axiosClient.get(`/api/2.0/projects/${id}`, config)
  },
  getProjects(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    config.params = payload.filter
    return axiosClient.get(`/api/2.0/projects`, config)
  },
  storeProject(Project) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    return axiosClient.post("/api/2.0/projects", Project, config)
  },
  storeProjectHistory(id) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    return axiosClient.get(`/api/2.0/project/history/${id}`, config)
  }
}
