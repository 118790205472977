import axios from "axios"
import Form from "vform"
import { useToast } from "vue-toastification"

const instance = axios.create({
  baseURL: process.env.VUE_APP_URL_API,
  headers: {
    "Content-Type": "application/json"
  },
  timeout: 480000 // Eight minutes
})

instance.interceptors.request.use((config) => {
  config.headers["Authorization"] = "Bearer " + localStorage.getItem("Token")
  return config
})

instance.interceptors.response.use((response) => {
  return response;
}, function (error) {
  // Do something with response error
  if (error.response.status === 403) {
    useToast().error("Error 403 - You don't have access to this action")
  }
  return Promise.reject(error.response);
});

Form.axios = instance

export default Form
