
/**
 * Make a config for all http request
 * @param {string} Token //string token
 * @returns {object} // config done
 */
const AuthToken = Token =>{

    if( Token ){
        const config = {headers:{ "Authorization": `Bearer ${Token} `}}
        return config
    }

}

export default AuthToken;
