import moment from "moment"

const state = {

  isMenuShortCutsOpen: false,
  isShortCutData: [7, 14, 30, 60, 90],
  isCalendarMenuOpen: false,
  timeline: {
    date_start: moment(new Date()).subtract(30, "days").format("YYYY-MM-DD 00:00:00"),
    date_end: moment(new Date()).format("YYYY-MM-DD 23:59:59")
  },
  mostPopularProducts: {
    date_init: moment(new Date()).subtract(30, "days").format("YYYY-MM-DD 00:00:00"),
    date_end: moment(new Date()).format("YYYY-MM-DD 23:59:59")
  },
  mostRelevantProducts: {
    date_init: moment(new Date()).subtract(30, "days").format("YYYY-MM-DD 00:00:00"),
    date_end: moment(new Date()).format("YYYY-MM-DD 23:59:59")
  },
  mostRelevantCategories: {
    date_init: moment(new Date()).subtract(30, "days").format("YYYY-MM-DD 00:00:00"),
    date_end: moment(new Date()).format("YYYY-MM-DD 23:59:59")
  }


}

const mutations = {

  setNewTimeLine(state, data) {
    state.timeline.date_start = data.date_start
    state.timeline.date_end = data.date_end
    // console.log(state)
  },
  resetTimeLine(state) {
    state.timeline.date_start = moment(new Date()).subtract(30, "days").format("YYYY-MM-DD 00:00:00")
    state.timeline.date_end = moment(new Date()).format("YYYY-MM-DD 23:59:59")
  },
  setPopularProducts(state, data) {
    state.mostPopularProducts.date_init = data.date_start
    state.mostPopularProducts.date_end = data.date_end
  },
  resetMostPopularProducts(state) {
    state.mostPopularProducts.date_init = moment(new Date()).subtract(30, "days").format("YYYY-MM-DD 00:00:00")
    state.mostPopularProducts.date_end = moment(new Date()).format("YYYY-MM-DD 23:59:59")
  },
  setRelevantProducts(state, data) {
    state.mostRelevantProducts.date_init = data.date_start
    state.mostRelevantProducts.date_end = data.date_end
  },
  resetMostRelevantProducts(state) {
    state.mostRelevantProducts.date_init = moment(new Date()).subtract(30, "days").format("YYYY-MM-DD 00:00:00")
    state.mostRelevantProducts.date_end = moment(new Date()).format("YYYY-MM-DD 23:59:59")
  },
  setRelevantCategories(state, data) {
    state.mostRelevantCategories.date_init = data.date_start
    state.mostRelevantCategories.date_end = data.date_end
  },
  resetMostRelevantCategories(state) {
    state.mostPopularProducts.date_init = moment(new Date()).subtract(30, "days").format("YYYY-MM-DD 00:00:00")
    state.mostPopularProducts.date_end = moment(new Date()).format("YYYY-MM-DD 23:59:59")
  },
  openMenuShortCut(state) {
    state.isMenuShortCutsOpen = true
  },
  closeMenuShortCut(state) {
    state.isMenuShortCutsOpen = false
  }

}

const actions = {

  setNewTimeLine({ commit }, data) {
    commit("setNewTimeLine", data)
  },
  resetTimeLine({ commit }) {
    commit("resetTimeLine")
  },
  setPopularProducts({ commit }, data) {
    commit("setPopularProducts", data)
  },
  resetMostPopularProducts({ commit }) {
    commit("resetMostPopularProducts")
  },
  setRelevantProducts({ commit }, data) {
    commit("setRelevantProducts", data)
  },
  resetMostRelevantProducts({ commit }) {
    commit("resetMostRelevantProducts")
  },
  setRelevantCategories({ commit }, data) {
    commit("setRelevantCategories", data)
  },
  resetMostRelevantCategories({ commit }) {
    commit("resetMostRelevantCategories")
  },

  openMenuShortCut({ commit }) {
    commit("openMenuShortCut")
  },
  closeMenuShortCut({ commit }) {
    commit("closeMenuShortCut")
  }

}

export const Calendar = {
  namespaced: true,
  state,
  mutations,
  actions
}
