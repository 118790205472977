<template>
  <transition
    enter-from-class="transform opacity-0 scale-95"
    enter-active-class="transition ease-out duration-100"
    enter-to-class="transform opacity-100 scale-100"
    leave-from-class="transform opacity-100 scale-100"
    leave-active-class="transition ease-in duration-75"
    leave-to-class="transform opacity-0 scale-95"
    mode="out-in"
  >
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: `TransitionFade2`,
};
</script>
