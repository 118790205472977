<template>
  <p class="flex flex-row items-center justify-center">
    <svg class="text-white bg-green-500 rounded-full mr-2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" :class="[size == 'normal' ? 'w-10 h-10 p-2' : '', size == 'small' ? 'w-8 h-8 p-1' : '']">
      <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
    </svg>
    <span v-if="showLogo" class="text-gray-700 font-semibold" :class="[size == 'normal' ? 'text-2xl' : '', size == 'small' ? 'text-lg' : '']"> SetUp </span>
  </p>
</template>

<script>
export default {
  name: "Brand",
  props: {
    size: {
      type: String,
      default: function () {
        return "normal"
      }
    },
    showLogo: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped></style>
