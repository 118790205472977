import { UtilitiesService } from "@/services/utilities"

const state = {
  timezones: []
}

const actions = {
  async timezones({ commit }) {
    let response = null
    try {
      response = await UtilitiesService.timezones()
      commit("setTimezones", response.data)
    } catch (error) {
      response = error.response
    }
    return response
  }
}

const mutations = {
  setTimezones(state, data) {
    state.timezones = data.data
  }
}

export const Utilities = {
  namespaced: true,
  state,
  actions,
  mutations
}
