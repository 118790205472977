import { AdminIntegrationService } from '../services/AdminIntegration'

const state = {
  loading: false,
  loadingAutoMapper: false,
  data: [],
  meta: {},
  links: {},
  authorized: true,
  fromData: [],
  fromDataError: [],
  services: [],
  toData: [],
  toDataError: [],
  initData: [],
  integrationsSelectedToFinish: [],
  lists: [],
  frequency: [],
  projectConnectionEdit: {},
}

const actions = {
  async list({ commit }, filter) {
    commit('resetState')
    commit('requestStart')
    try {
      let response = await AdminIntegrationService.list(filter)
      commit('setData', response.data)
    } catch (error) {
      if (error.toString().includes("403")) {
        commit('setAuthorized', false)
      }
    }
    commit('requestEnd')
  },
  clearState ({ commit }) {
    commit('resetState')
  },
  async startIntegration({ commit }, payload) {
    let result = null
    try {
      result = await AdminIntegrationService.startIntegration(payload)
      commit('setInitData', result.data)
    } catch (error) {
      result = error.response
    }
    return result

  },
  async setFromData({ commit, dispatch }, payload) {
    let data = {}
    data.service_id = payload.service_id
    let fromData = null
    commit('requestStart')
    fromData = await AdminIntegrationService.validateFrom(payload)
    data.fields = fromData.data.fields ? fromData.data.fields : fromData.data
    dispatch('acumulateFromData', data)
    commit('requestEnd')
    return fromData
  },
  async getFromData(aux, payload) {
    let fromData = null
    fromData = await AdminIntegrationService.validateFrom(payload)
    return fromData
  },
  acumulateFromData({ state, dispatch }, prop) {
    state.fromData = [...state.fromData, prop]
    dispatch('saveFieldsLocalStorageFrom', state.fromData)
  },
  acumulateFromDataErrors({ state }, prop) {
    state.fromDataError = [...state.fromDataError, prop]
  },
  saveFieldsLocalStorageFrom(aux, prop) {
    localStorage.setItem('fromData', JSON.stringify(prop))
  },
  async setToData({ commit, dispatch }, payload) {
    let toData = null
    toData = await AdminIntegrationService.validateTo(payload)
    commit('setToData', toData.data)
    dispatch('saveFieldsLocalStorageTo', toData.data)
    return toData

  },
  saveFieldsLocalStorageTo(aux, toData) {
    localStorage.setItem('toData', JSON.stringify(toData))
  },
  async confirmConfig(aux, payload) {
    let config = null
    try {
      config = await AdminIntegrationService.config(payload)
    } catch (error) {
      config = error.response

    }
    return config
  },
  async changeProjectConnection(aux, paramters) {
    let response = null
    try {
      response = await AdminIntegrationService.changeProjectConnection(paramters)
    } catch (error) {
      response = error.response

    }
    return response
  },
  async changeIntegration(aux, paramters) {
    let response = null
    try {
      response = await AdminIntegrationService.changeIntegration(paramters)
    } catch (error) {
      response = error.response

    }
    return response
  },
  async requestLists({ dispatch, commit }, payload){

    let lists = null
    try {
      lists = await AdminIntegrationService.requestList(payload)
      dispatch('saveListData', lists.data)
      commit('setLists', lists.data)
    } catch (error) {
      lists = error.response
    }

    return lists
  },
  saveListData(aux, lists){
    localStorage.setItem('lists', JSON.stringify(lists))
  },
  async requestGroups({ commit }, payload){
    commit('resetGroupsData')

    let groups = null
    try {
      groups = await AdminIntegrationService.requestGroups(payload)
      commit('setGroupsData', groups.data)
    } catch (error) {
      groups = error.response
    }

    return groups
  },
  integrationsSelectedToFinish({ commit }, payload) {
    commit('integrationsSelectedToFinish', payload)
  },
  async autoMapper({state }, payload) {
    state.loadingAutoMapper = true
    let AutoMapper = null
    try {
      AutoMapper = await AdminIntegrationService.autoMapper(payload)
      // commit('setAutoMapper', AutoMapper)
    } catch (error) {
      AutoMapper = error.response
    }

    state.loadingAutoMapper = false
    return AutoMapper
  },
  async frequency({commit}){
    let result = null
    try {
      result = await AdminIntegrationService.frequency()
      commit('setFrequency', result.data.data)
    } catch (error) {
      result = error.response
    }
    return result
  },
  resetIntegration(){
    localStorage.removeItem('fromData')
    localStorage.removeItem('lists')
    localStorage.removeItem('integrationId')
    localStorage.removeItem('project')
    localStorage.removeItem('channel')
    localStorage.removeItem('integrationsCredentials')
    localStorage.removeItem('services')
    localStorage.removeItem('toData')
    localStorage.removeItem('isDataToFinish')
    localStorage.removeItem('isShowCheckCredentials')
    localStorage.removeItem('integrationsCredentials')
    localStorage.removeItem('oauth2Mailup')
  },
  async show(aux, paramters) {
    let response = null
    try {
      response = await AdminIntegrationService.show(paramters)
    } catch (error) {
      response = error.response

    }
    return response
  },
}

const mutations = {
  resetState (state) {
    state.loading = false
    state.data = []
    state.meta = {}
    state.links = {}
    state.authorized = true
  },
  requestStart(state) {
    state.loading = true
  },
  requestEnd(state) {
    state.loading = false
  },
  setData(state, data) {
    state.data = data.data
    state.meta = data.meta
    state.links = data.link
  },
  setAuthorized(state, authorized) {
    state.authorized = authorized
  },
  setInitData(state, data) {
    state.initData = data
  },
  setToData(state, data) {
    state.toData = data
  },
  integrationsSelectedToFinish(state, data) {
    state.integrationsSelectedToFinish = data
  },
  setLists(state, data){
    state.lists = data
  },
  setFrequency(state, data){
    state.frequency = data
  },
  setprojectConnectionEdit(state, data){
    state.projectConnectionEdit = data;
  },
  resetFormErrors(state){
    state.fromDataError = [];
  }
}

export const AdminIntegration = {
  namespaced: true,
  state,
  actions,
  mutations
}
