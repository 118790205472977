const SwalConfig = {
  buttonsStyling: false,
  reverseButtons: false,
  focusConfirm: false,
  customClass: {
    container: 'text-base',
    popup: 'p-0',
    header: 'pt-5',
    title: 'text-base font-semibold my-2',
    // closeButton: '...',
    icon: 'm-0 h-10 w-10 border-2 border-solid border-white',
    // image: '...',
    content: 'text-base',
    // input: '...',
    // inputLabel: '...',
    // validationMessage: '...',
    actions: 'bg-gray-100 rounded-b py-4 flex gap-2',
    confirmButton: 'btn btn-primary btn-block flex-1',
    denyButton: 'btn btn-danger btn-block flex-1',
    cancelButton: 'btn btn-accent btn-block flex-1',
    // loader: '...',
    footer: 'bg-white'
  }
};

export default SwalConfig
