import { createWebHistory, createRouter } from "vue-router"
import Store from "@/store"
import { DashboardServices } from "@/services/Dashboard"

// import { ref } from 'vue'
// import { useRouter } from 'vue-router'

const routes = [
  {
    path: "/",
    redirect: { name: "Login" }
  },
  {
    path: "/:id?/projects/",
    name: "Projects",
    component: () => import("@/views/Projects"),
  },
  {
    path: "/:id?/guide",
    name: "Guide",
    component: () => import("@/views/Guide")
  },
  {
    path: "/:id?/dashboard/",
    name: "Dashboard",
    component: () => import("@/views/Dashboard")
  },
  {
    path: "/:id?/design/",
    name: "Design",
    props: true,
    component: () => import("@/views/Design"),
    meta: {
      permissions: ['v2.client.template.index'],
      feature: 'design'
    }
  },
  {
    path: "/:id?/reports/",
    name: "Reports",
    props: true,
    component: () => import("@/views/Reports"),
    meta: {
      permissions: ['v2.client.report.index'],
      feature: 'reports'
    }
  },
  {
    path: "/:id?/design/templates/builder",
    name: "Template Builder",
    props: true,
    meta: {
      plainLayout: true,
      isLayoutFull: true,
      permissions: ['v2.client.template.storeOrUpdate'],
      feature: 'design'
    },
    component: () => import("@/views/TemplateBuilder")
  },
  {
    path: "/:id?/design/templates/:templateId/builder",
    name: "Edit Template",
    props: true,
    meta: {
      plainLayout: true,
      isLayoutFull: true,
      permissions: ['v2.client.template.storeOrUpdate'],
      feature: 'design'
    },
    component: () => import("@/views/TemplateBuilder")
  },
  {
    path: "/:id?/design/blocks/builder",
    name: "Block Builder",
    props: true,
    meta: {
      plainLayout: true,
      isLayoutFull: true,
      permissions: ['v2.client.template.storeOrUpdate'],
      feature: 'design'
    },
    component: () => import("@/views/BlockBuilder")
  },
  {
    path: "/:id?/design/blocks/:blockId/builder",
    name: "Edit Block",
    props: true,
    meta: {
      plainLayout: true,
      isLayoutFull: true,
      permissions: ['v2.client.template.storeOrUpdate'],
      feature: 'design'
    },
    component: () => import("@/views/BlockBuilder")
  },
  {
    path: "/:id?/audience/",
    name: "Audience",
    component: () => import("@/views/Audience"),
    meta: {
      permissions: ['v2.client.project.show.segments'],
      feature: 'segment'
    }
  },
  {
    path: "/:id?/audience/segment/:segmentId?",
    name: "Segment Profiles",
    component: () => import("@/views/ShowSegmentProfiles"),
    meta: {
      permissions: ['v2.client.segments.show.profile'],
      feature: 'segment'
    }
  },
  {
    path: "/:id?/audience/profiles",
    name: "All Profiles",
    component: () => import("@/views/ShowAllProfiles"),
    meta: {
      permissions: ['v2.client.project.show.profiles'],
      feature: 'segment'
    }
  },
  {
    path: "/audience/credential-success",
    name: "CredentialSuccess",
    component: () => import("@/views/Credential/CredentialSuccess"),
    meta: {
      plainLayout: true
    }
  },
  {
    path: "/:id?/content/",
    name: "Content",
    component: () => import("@/views/Content"),
    meta: {
      permissions: ['v2.client.project.content.index']
    }
  },
  {
    path: "/:id?/project/form",
    name: "New Project",
    component: () => import("@/views/Project/FormProject"),
    meta: {
      plainLayout: true
    }
  },
  {
    path: "/:id?/integrations",
    name: "Integrations",
    component: () => import("@/views/Project/Integrations"),
    meta: {
      permissions: ['v2.client.project_connection.index'],
      feature: 'integration'
    }
  },
  {
    path: "/:id?/integrations/form-integration/:projectConnectionId?",
    name: "Form Integration",
    component: () => import("@/views/Project/Wizard/FormIntegration"),
    meta: {
      permissions: ['v2.client.integration.update.automapper'],
      feature: 'integration'
    }
  },
  {
    path: "/:id?/project-profile",
    name: "Project Profile",
    component: () => import("@/views/Project/ProjectProfile"),
    meta: {
      permissions: ['v2.client.project.update']
    }
  },
  // check view, it is desactive
  {
    path: "/:id?/project-preferences",
    name: "Project Preferences",
    component: () => import("@/views/Project/ProjectPreferences")
  },
  {
    path: "/:id?/project-team",
    name: "Project Team",
    component: () => import("@/views/Project/ProjectTeam"),
    meta: {
      permissions: ['v2.client.project.invitations']
    }
  },
  {
    path: "/:id?/project-functions",
    name: "Project Functions",
    component: () => import("@/views/Project/ProjectFunctions"),
    meta: {
      permissions: ['v2.client.project.change.active.console']
    }
  },
  {
    path: "/:id?/contact-admin",
    name: "Contact to admin",
    component: () => import("@/views/Project/ProjectContact"),
  },
  {
    path: "/project/:id/channel/connections",
    name: "Channels",
    component: () => import("@/views/Project/Channels"),
    meta: {
      permissions: ['v2.client.project.get.credentials'],
      feature: 'integration'
    }
  },
  // check
  {
    path: "/project/:id/channel/connections/:channelId",
    name: "Connections",
    component: () => import("@/views/Project/Wizard/Connections"),
    meta: {
      permissions: ['v2.client.project_connection.index'],
      feature: 'integration'
    }
  },
  {
    path: "/:id?/user/profile",
    name: "Profile",
    component: () => import("@/views/User/Profile")
  },
  {
    path: "/:id?/user/profile/preferences",
    name: "Preferences",
    component: () => import("@/views/User/ProfilePreferences")
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    meta: {
      plainLayout: true
    }
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/Register.vue"),
    meta: {
      plainLayout: true
    }
  },
  {
    path: "/onboarding/user",
    name: "Onboarding user",
    component: () => import("@/components/Register/Onboarding.vue"),
    meta: {
      plainLayout: true
    }
  },
  {
    path: "/onboarding/user/verification",
    name: "Verification user",
    component: () => import("@/views/Verification.vue"),
    meta: {
      plainLayout: true
    }
  },
  {
    path: "/onboarding/project",
    name: "Onboarding project",
    component: () => import("@/components/Register/Project.vue"),
    meta: {
      plainLayout: true
    }
  },
  {
    path: "/onboarding/redirect",
    name: "Onboarding redirect",
    component: () => import("@/components/Register/Redirect.vue"),
    meta: {
      plainLayout: true
    }
  },
  {
    path: "/invitations",
    name: "Invitations",
    component: () => import("@/components/User/Invitations.vue"),
    meta: {
      plainLayout: true
    }
  },
  {
    path: "/forgot",
    name: "Reset",
    component: () => import("@/views/Forgot.vue"),
    meta: {
      plainLayout: true
    }
  },
  {
    path: "/reset",
    name: "Confirm",
    component: () => import("@/views/ResetPassword.vue"),
    meta: {
      plainLayout: true
    }
  },
  {
    path: "/m/login",
    name: "LoginMailUp",
    component: () => import("@/views/MailUp/Login.vue"),
    meta: {
      plainLayout: true
    }
  },
  {
    path: "/m/dashboard",
    name: "DashboardMailup",
    component: () => import("@/views/MailUp/Dashboard.vue"),
    meta: {
      plainLayout: true
    }
  },
  {
    path: "/m/create-account",
    name: "CreateAccountMailUp",
    component: () => import("@/views/MailUp/CreateAccount.vue"),
    meta: {
      plainLayout: true
    }
  },
  {
    path: "/a/login",
    name: "LoginAcumbamail",
    component: () => import("@/views/Acumbamail/Login.vue"),
    meta: {
      plainLayout: true
    }
  },
  {
    path: "/a/dashboard",
    name: "DashboardAcumbamail",
    component: () => import("@/views/Acumbamail/Dashboard.vue"),
    meta: {
      plainLayout: true
    }
  },
  {
    path: "/a/create-account",
    name: "CreateAccountAcumbamail",
    component: () => import("@/views/Acumbamail/CreateAccount.vue"),
    meta: {
      plainLayout: true
    }
  },
  {
    path: "/invite-404",
    component: () => import("@/views/User/InviteNotFound.vue"),
    name: "Invite not found",
    meta: {
      plainLayout: true
    }
  },
  {
    path: "/iframe",
    component: () => import("@/views/Iframe"),
    name: "Iframe",
    meta: {
      plainLayout: true
    }
  },
  {
    path: "/administrator",
    name: "Admin",
    component: () => import("@/views/Admin/Administrator.vue")
  },
  {
    path: "/administrator/users",
    name: "Admin Users",
    component: () => import("@/views/Admin/Users/Users.vue")
  },
  {
    path: "/administrator/users/:id/show",
    name: "Admin users show",
    props: true,
    component: () => import("@/views/Admin/Users/Show")
  },
  {
    path: "/administrator/users/:id/edit",
    name: "Admin users edit",
    props: true,
    component: () => import("@/views/Admin/Users/Edit")
  },
  {
    path: "/administrator/users/:id/password",
    name: "Admin users password",
    props: true,
    component: () => import("@/views/Admin/Users/EditPassword")
  },
  {
    path: "/administrator/projects",
    name: "Admin Projects",
    component: () => import("@/views/Admin/Projects/Projects.vue")
  },
  {
    path: "/administrator/projects/:id/show",
    name: "Admin project show",
    props: true,
    component: () => import("@/views/Admin/Projects/Show")
  },
  {
    path: "/administrator/projects/:id/edit",
    name: "Admin project edit",
    props: true,
    component: () => import("@/views/Admin/Projects/Edit")
  },
  {
    path: "/administrator/integrations",
    name: "Admin Integrations",
    component: () => import("@/views/Admin/Integrations/Integrations.vue")
  },
  {
    path: "/administrator/integrations/logs/:integration_id",
    name: "Admin Log Integrations",
    component: () => import("@/views/Admin/Integrations/Logs.vue")
  },
  {
    path: "/administrator/accounts",
    name: "Admin Accounts",
    component: () => import("@/views/Admin/Accounts/Accounts.vue")
  },
  {
    path: "/m/mercadoshops/welcome",
    name: "WelcomeMailUp",
    component: () => import("@/views/MercadoShops/MailUp.vue"),
    meta: {
      plainLayout: true
    }
  },
  {
    path: "/a/mercadoshops/welcome",
    name: "WelcomeAcumbamail",
    component: () => import("@/views/MercadoShops/Acumbamail.vue"),
    meta: {
      plainLayout: true
    }
  },
  {
    path: "/:id?/:catchAll(.*)*",
    component: () => import("@/views/NotFound"),
    name: "Not Found",
    meta: {
      plainLayout: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return (
      savedPosition ||
      new Promise((resolve) => {
        setTimeout(() => resolve({ top: 0, behavior: "smooth" }), 300)
      })
    )
  }
})

router.beforeEach(async (to, from, next) => {
  // Change Language
  if (to.query?.language) {
    let languageFound = Store.state.Langs.availables.find((language) => language.lang == to.query.language)
    if (languageFound) {
      Store.dispatch("Langs/changeAppLang", languageFound.lang)
      this.$moment.locale(languageFound.lang)
    }
  }

  let admin = "administrator"
  if (to.path.includes(admin)) {
    Store.dispatch("User/getAdminNav")
  } else {
    Store.dispatch("User/getClientNav")
  }

  let body = document.getElementsByTagName("body")[0]

  if (["CredentialSuccess"].indexOf(to.name) > -1) {
    document.title = `OAuth 2.0`
  } else if (["LoginMailUp", "CreateAccountMailUp", "DashboardMailup"].indexOf(to.name) > -1) {
    document.title = `MailUp`
    document.getElementById("favicon").setAttribute("href", "https://ik.imagekit.io/mailup/es/app/themes/mailup/public/images/favicon.ico?id=d097155787428272868fedd8c00d7848")
  } else if (["LoginAcumbamail", "CreateAccountAcumbamail", "DashboardAcumbamail"].indexOf(to.name) > -1) {
    document.title = `Acumbamail`
    document.getElementById("favicon").setAttribute("href", "https://acumbamail.com/static/favico/Acumbamail/favicon.45774eb5c15e.ico")
  } else if (["WelcomeMailUp", "WelcomeAcumbamail"].indexOf(to.name) > -1) {
    if (to.name == "WelcomeMailUp") document.title = `MailUp + Mercado Shops`
    if (to.name == "WelcomeAcumbamail") document.title = `Acumbamail + Mercado Shops`
    document.getElementById("favicon").setAttribute("href", "https://http2.mlstatic.com/frontend-assets/ms-web-navigation/ui-navigation/5.22.1/mercadoshops/favicon.svg")
  } else {
    document.title = `SetUp - ${to.name}`
  }

  Store.dispatch("Langs/initAppLang")
  Store.dispatch("Langs/closeMenuLangs")
  Store.dispatch("Menu/closeSideMenu")
  Store.dispatch("Menu/closeProfileMenu")
  Store.dispatch("Menu/closeSettingsForms")
  Store.dispatch("Menu/closeNotificationPanel")

  if ("Dashboard".indexOf(from.name) > -1 && to.name != "Dashboard") {
    DashboardServices.requestCancelToken()
  }
  if ("Dashboard".indexOf(to.name) > -1) {
    DashboardServices.resetCancelToken()
  }
  if (["Signup", "Reset", "Confirm", "Login", "Register", "Onboarding user", "Onboarding project", "LoginMailUp", "CreateAccountMailUp", "DashboardMailup", "CredentialSuccess", "Invite not found", "LoginAcumbamail", "CreateAccountAcumbamail", "DashboardAcumbamail", "Iframe", "Admin", "WelcomeAcumbamail", "WelcomeMailUp"].indexOf(to.name) > -1) {
    if (["LoginMailUp", "CreateAccountMailUp", "DashboardMailup", "LoginAcumbamail", "CreateAccountAcumbamail", "DashboardAcumbamail", "WelcomeAcumbamail", "WelcomeMailUp"].indexOf(to.name) > -1) {
      body.classList.remove("appBackground")
      if (["LoginMailUp", "CreateAccountMailUp", "DashboardMailup"].indexOf(to.name) > -1) {
        body.classList.add("appMailupBackground")
      }
      if (["LoginAcumbamail", "CreateAccountAcumbamail", "DashboardAcumbamail", "WelcomeAcumbamail", "WelcomeMailUp"].indexOf(to.name) > -1) {
        body.classList.add("appAcumbamailBackground")
      }
    }

    if (["Signup", "Reset", "Confirm", "Login", "Register", "Onboarding user", "Onboarding project", "CredentialSuccess", "Iframe", "Admin"].indexOf(to.name) > -1) {
      body.classList.remove("appBackground")
      body.classList.add("appAuthBackground")
    }

    return next()
  } else {
    body.classList.remove("appAuthBackground")
    body.classList.add("appBackground")
    let session = await Store.dispatch("Auth/sessionStart")
    if (!JSON.stringify(localStorage.getItem("externalLogin"))) {
      if (session) {
        return next()
      } else {
        if (to.name == "Confirm") {
          return next()
        } else {
          return next("/login")
        }
      }
    } else {
      if (session) return next()
      /* else if(session) next('/m/dashboard'); */ else return next("/m/login")
    }
  }
})

export default router
