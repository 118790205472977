import axiosClient from "@/config/AxiosClient"
import AuthToken from "@/config/AuthToken"

export const ChannelServices = {
  getChannels(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    config.params = payload.filter
    return axiosClient.get("/api/2.0/channels", config)
  },
  getChannel(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    const { id } = payload
    return axiosClient.get(`/api/2.0/channels/${id}`, config)
  }
}
