const state = {
  isSideMenuOpen: false,
  isProfileMenuOpen: false,
  isSettingsFormsOpen: false,
  isNotificationPanelOpen: false,
  isModalQueryFilterOpen: false,
  isSwitchProjectModalOpen: false,
  isNewSegmentModalOpen: false,
  isGridSegment: false,
  isListSegment: true,
  isGridIntegrationsView: true,
  isListIntegrationsView: false,
  isProfilesModalOpen: false,
  isLibrariesModalOpen: false,
  isMenuInviteOpen: true,
  isModalMiniOpen: false,
  isSelectGenderOpen: false,
  iseSelectGeolocalizationOpen: false,
  isChannelsOptionsOpen: false,
  isDatesOptionsOpen: false,
  isCollectionsOptionsOpen: false,
  isModalCollectionOpen: false,
  isContentCardModalOpen: false,


}
const mutations = {
  openSideMenu(state) { state.isSideMenuOpen = true },
  closeSideMenu(state) { state.isSideMenuOpen = false },

  openProfileMenu(state) { state.isProfileMenuOpen = true },
  closeProfileMenu(state) { state.isProfileMenuOpen = false },

  openNotificationPanel(state) { state.isNotificationPanelOpen = true },
  closeNotificationPanel(state) { state.isNotificationPanelOpen = false },

  openSettingsForms(state) { state.isSettingsFormsOpen = true },
  closeSettingsForms(state) { state.isSettingsFormsOpen = false },

  OpenModalQueryFilter(state) { state.isModalQueryFilterOpen = true },
  CloseModalQueryFilter(state) { state.isModalQueryFilterOpen = false },

  openNewSegmentModal(state) { state.isNewSegmentModalOpen = true },
  closeNewSegmentModal(state) { state.isNewSegmentModalOpen = false },

  openSwitchProjectModal(state) { state.isSwitchProjectModalOpen = true },
  closeSwitchProjectModal(state) { state.isSwitchProjectModalOpen = false },

  openProfilesModal(state) { state.isProfilesModalOpen = true },
  closeProfilesModal(state) { state.isProfilesModalOpen = false },

  openLibrariesModal(state) { state.isLibrariesModalOpen = true },
  closeLibrariesModal(state) { state.isLibrariesModalOpen = false },

  changeGridSegments(state) {
    state.isGridSegment = true
    state.isListSegment = false
  },
  changeListSegments(state) {
    state.isGridSegment = false
    state.isListSegment = true
  },
  openMenuInvite(state) { state.isMenuInviteOpen = true },
  closeMenuInvite(state) { state.isMenuInviteOpen = false },
  changeMenuInvite(state) { state.isModalMiniOpen ? state.isModalMiniOpen = false : state.isModalMiniOpen = true },

  openSelectGender(state) { state.isSelectGenderOpen = true },
  closeSelectGender(state) { state.isSelectGenderOpen = false },
  openSelectGeolocalization(state) { state.iseSelectGeolocalizationOpen = true },
  closeSelectGeolocalization(state) { state.iseSelectGeolocalizationOpen = false },

  openSelectCollections(state) { state.isCollectionsOptionsOpen = true },
  closeSelectCollections(state) { state.isCollectionsOptionsOpen = false },
  openSelectChannels(state) { state.isChannelsOptionsOpen = true },
  closeSelectChannels(state) { state.isChannelsOptionsOpen = false },
  openSelectDates(state) { state.isDatesOptionsOpen = true },
  closeSelectDates(state) { state.isDatesOptionsOpen = false },

  openCollectionModal(state) {
    state.isTopNavModalCollection = true
    state.isModalCollectionOpen = true
  },
  closeCollectionModal(state) {
    state.isModalCollectionOpen = false
    state.isTopNavModalCollection = false
  },
  openContentCardModal(state) { state.isContentCardModalOpen = true },
  closeContentCardModal(state) { state.isContentCardModalOpen = false },


}
const actions = {
  openSideMenu({ commit }) { commit('openSideMenu') },
  closeSideMenu({ commit }) { commit('closeSideMenu') },

  openProfileMenu({ commit }) { commit('openProfileMenu') },
  closeProfileMenu({ commit }) { commit('closeProfileMenu') },

  openNotificationPanel({ commit }) { commit('openNotificationPanel') },
  closeNotificationPanel({ commit }) { commit('closeNotificationPanel') },

  openSettingsForms({ commit }) { commit('openSettingsForms') },
  closeSettingsForms({ commit }) { commit('closeSettingsForms') },

  openModalQueryFilter({ commit }) { commit('OpenModalQueryFilter') },
  closeModalQueryFilter({ commit }) { commit('CloseModalQueryFilter') },

  openNewSegmentModal({ commit }) { commit('openNewSegmentModal') },
  closeNewSegmentModal({ commit }) { commit('closeNewSegmentModal') },

  changeGridSegments({ commit }) { commit('changeGridSegments') },
  changeListSegments({ commit }) { commit('changeListSegments') },

  openProfilesModal({ commit }) { commit('openProfilesModal') },
  closeProfilesModal({ commit }) { commit('closeProfilesModal') },

  openLibrariesModal({ commit }) { commit('openLibrariesModal') },
  closeLibrariesModal({ commit }) { commit('closeLibrariesModal') },

  openMenuInvite({ commit }) { commit('openMenuInvite') },
  closeMenuInvite({ commit }) { commit('closeMenuInvite') },
  changeMenuInvite({ commit }) { commit('changeMenuInvite') },

  openSelectGender({ commit }) { commit('openSelectGender') },
  closeSelectGender({ commit }) { commit('closeSelectGender') },
  openSelectGeolocalization({ commit }) { commit('openSelectGeolocalization') },
  closeSelectGeolocalization({ commit }) { commit('closeSelectGeolocalization') },

  openSelectCollections({ commit }) { commit('openSelectCollections') },
  closeSelectCollections({ commit }) { commit('closeSelectCollections') },
  openSelectChannels({ commit }) { commit('openSelectChannels') },
  closeSelectChannels({ commit }) { commit('closeSelectChannels') },
  openSelectDates({ commit }) { commit('openSelectDates') },
  closeSelectDates({ commit }) { commit('closeSelectDates') },

  openCollectionModal({ commit }) { commit('openCollectionModal') },
  closeCollectionModal({ commit }) { commit('closeCollectionModal') },

  openContentCardModal({ commit }) { commit('openContentCardModal') },
  closeContentCardModal({ commit }) { commit('closeContentCardModal') },


}



export const Menu = {
  namespaced: true,
  state,
  actions,
  mutations
}
