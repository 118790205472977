<template>
  <div>
    <nav class="hidden md:mr-auto md:flex md:flex-wrap md:justify-between md:items-center HeaderMainNav">
      <div class="flex row justify-start items-center">
        <div class="border-r border-gray-100 pr-2 mr-2">
          <router-link :to="{ name: 'Onboarding redirect' }" class="flex title-font font-medium items-center text-white mr-2 relative">
            <Brand size="normal" />
          </router-link>
        </div>
        <div>
          <router-link :to="{ name: 'Admin' }" class="pt-[1.5rem] mx-1 px-2 inline-block cursor-pointer text-sm font-semibold text-gray-500 focus:outline-none transition">
            <div class="flex-center space-x-2">
              <Icon icon="tabler:home" class="w-4 h-4" />
              <span>{{ $t("navbar.admin") }}</span>
            </div>
            <span class="transition mt-3.5 h-1 w-full block decorative rounded-t"></span>
          </router-link>
          <router-link :to="{ name: 'Admin Users' }" class="pt-[1.5rem] mx-1 px-2 inline-block cursor-pointer text-sm font-semibold text-gray-500 focus:outline-none transition">
            <div class="flex-center space-x-2">
              <Icon icon="tabler:users" class="w-4 h-4" />
              <span>{{ $t("navbar.users") }}</span>
            </div>
            <span class="transition mt-3.5 h-1 w-full block decorative rounded-t"></span>
          </router-link>
          <router-link :to="{ name: 'Admin Projects' }" class="pt-[1.5rem] mx-1 px-2 inline-block cursor-pointer text-sm font-semibold text-gray-500 focus:outline-none transition">
            <div class="flex-center space-x-2">
              <Icon icon="tabler:list-details" class="w-4 h-4" />
              <span>{{ $t("navbar.projects") }}</span>
            </div>
            <span class="transition mt-3.5 h-1 w-full block decorative rounded-t"></span>
          </router-link>
          <router-link :to="{ name: 'Admin Integrations' }" class="pt-[1.5rem] mx-1 px-2 inline-block cursor-pointer text-sm font-semibold text-gray-500 focus:outline-none transition">
            <div class="flex-center space-x-2">
              <Icon icon="tabler:plug" class="w-4 h-4" />
              <span>{{ $t("navbar.integrations") }}</span>
            </div>
            <span class="transition mt-3.5 h-1 w-full block decorative rounded-t"></span>
          </router-link>
          <router-link :to="{ name: 'Admin Accounts' }" class="pt-[1.5rem] mx-1 px-2 inline-block cursor-pointer text-sm font-semibold text-gray-500 focus:outline-none transition">
            <div class="flex-center space-x-2">
              <Icon icon="tabler:user-search" class="w-4 h-4" />
              <span>{{ $t("navbar.accounts") }}</span>
            </div>
            <span class="transition mt-3.5 h-1 w-full block decorative rounded-t"></span>
          </router-link>
          <!-- to client -->
          <router-link :to="{ name: 'Projects', params: { id: $store.state.Projects.idRoute } }" class="pt-[1.5rem] mx-1 px-2 inline-block cursor-pointer text-sm font-semibold text-gray-500 focus:outline-none transition">
            <div class="flex-center space-x-2">
              <Icon icon="tabler:login" class="w-4 h-4" />
              <span>{{ $t("navbar.client") }}</span>
            </div>
            <span class="transition mt-3.5 h-1 w-full block decorative rounded-t"></span>
          </router-link>
        </div>
      </div>
    </nav>
  </div>
  <div>
    <ul class="flex items-center HeaderMainNav">
      <li class="relative" v-click-away="closeProfileMenu">
        <button @click="toggleProfileMenu" class="items-center min-h-full group align-middle pt-3 mx-2 px-2 cursor-pointer text-sm text-gray-400 focus:outline-none hover:text-gray-100 transition" :class="[$store.state.Menu.isProfileMenuOpen ? 'open' : '']" aria-label="Account" aria-haspopup="true">
          <div class="flex items-center">
            <div class="avatar placeholder">
              <div class="bg-gray-300 text-gray-700 rounded-full w-8 h-8 inline md:mr-5 ring ring-white ring-offset-green-500 ring-offset-2" v-if="$store.state.Auth.user">
                <span><img :src="$store.state.Auth.user.avatar" class="rounded-full w-10" /></span>
              </div>
            </div>
            <div class="flex flex-col items-start">
              <p class="text-sm text-gray-700 group-hover:text-gray-600 hidden md:inline" v-if="$store.state.Auth.user">{{ $store.state.Auth.user.name }}</p>
              <p class="text-xs text-gray-500 hidden md:inline" v-if="$store.state.Projects.activeProject">
                <span>{{ $store.state.Projects.activeProject.name || $store.state.Projects.activeProject.company }}</span>
              </p>
            </div>
          </div>
          <span class="decorative transition mt-2.5 h-1 w-full block rounded-t"></span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue"
import Brand from "@/components/Brand.vue"

export default {
  name: "NavbarAdmin",

  components: {
    Icon,
    Brand
  },

  props: {
    navPro: {
      type: Boolean,
      required: false
    },
    navFree: {
      type: Boolean,
      required: false
    },
    navWithoutProject: {
      type: Boolean,
      required: false
    },
    dropdown: {
      type: Boolean,
      required: false
    }
  },

  data() {
    return {
      currentProject: this.$route.params.id ? this.$route.params.id : null
    }
  },

  watch: {
    "$route.params.id"() {
      this.currentProject = this.$route.params.id
    }
  },

  created() {
    if (this.currentProject) this.getProject(this.currentProject)
  },

  methods: {
    toggleProfileMenu() {
      if (this.$store.state.Menu.isProfileMenuOpen) {
        this.closeProfileMenu()
      } else {
        this.openProfileMenu()
      }
    },

    openProfileMenu() {
      this.$store.dispatch("Menu/openProfileMenu")
    },

    closeProfileMenu() {
      this.$store.dispatch("Menu/closeProfileMenu")
    },

    getProject(id) {
      this.$store.dispatch("Projects/getProject", id)
    },

    logOut() {
      this.$store.dispatch("Auth/requestLogout")
    }
  }
}
</script>
