<template>
  <div class="w-full h-full flex flex-col justify-center items-center mt-32">
    <div class="space-y-4 flex flex-col items-center justify-center">
      <h2 class="text-gray-600 font-semibold text-md lg:text-2xl uppercase">{{ t("403.title") }}</h2>
      <p class="w-4/5 lg:w-full text-sm lg:text-md text-gray-400" >{{ t("403.info") }}</p >
      <!-- current project PRO -->
      <div v-if="$store.state.Projects.isPremiumProject == 1">
        <router-link :to="{ name: 'Dashboard', params: { id: '' } }">
          <button class="btn btn-primary btn-xs">
            <span>{{ t("403.toDashboard") }}</span>
          </button>
        </router-link>
      </div>
      <!-- current project LIGHT -->
      <div v-if="$store.state.Projects.isPremiumProject == 0">
        <router-link :to="{ name: 'Guide', params: { id: '' } }">
          <button class="btn btn-primary btn-xs">
            <span>{{ t("403.toGuide") }}</span>
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import{useI18n} from 'vue-i18n'

export default {
  setup(){
    const { t, locale } = useI18n();
    return { t , locale }
  }
}
</script>
