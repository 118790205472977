import { AdminUserService } from '../services/AdminUser'

const state = {
  loading: false,
  data: [],
  meta: {},
  links: {},
  authorized: true,
}

const actions = {
  async list({ commit }, filter) {
    commit('resetState')
    commit('requestStart')
    let response = null
    try {
      response = await AdminUserService.list(filter)
      commit("setData", response.data)
    } catch (error) {
      response = error.response
    }
    commit('requestEnd')
    return response
  },
  async show (aux, id) {
    let response = null
    try {
      response = await AdminUserService.show(id)
    } catch (error) {
      response = error.response
    }
    return response
  },
  async store(aux, values) {
    let response = null
    try {
      response = await AdminUserService.store(values)
    } catch (error) {
      response = error.response
    }
    return response
  },
  async update(aux, values) {
    let response = null
    try {
      response = await AdminUserService.update(values.id, values)
    } catch (error) {
      response = error.response
    }
    return response
  },
  async updatePassword(aux, values) {
    let response = null
    try {
      response = await AdminUserService.updatePassword(values.id, values)
    } catch (error) {
      response = error.response
    }
    return response
  },
  async enabled (aux, values) {
    let response = null
    try {
      response = await AdminUserService.enabled(values.id, values.is_active)
    } catch (error) {
      response = error.response
    }
    return response
  },
  async remove(aux, id) {
    let response = null
    try {
      response = await AdminUserService.remove(id)
    } catch (error) {
      response = error.response
    }
    return response
  },
  clearState ({ commit }) {
    commit('resetState')
  },
}

const mutations = {
  resetState (state) {
    state.loading = false
    state.data = []
    state.meta = {}
    state.links = {}
    state.authorized = true
  },
  requestStart(state) {
    state.loading = true
  },
  requestEnd(state) {
    state.loading = false
  },
  setData(state, data) {
    state.data = data.data
    state.meta = data.meta
    state.links = data.link
  },
  setAuthorized(state, authorized) {
    state.authorized = authorized
  },
}

export const AdminUsers = {
  namespaced: true,
  state,
  actions,
  mutations
}
