<template>
  <div>
    <nav class="hidden md:mr-auto md:flex md:flex-wrap md:justify-between md:items-center HeaderMainNav">
      <div class="flex row justify-start items-center">
        <div class="border-r border-gray-100 pr-2 mr-2">
          <router-link :to="{ name: 'Dashboard', params: { id: $store.state.Projects.idRoute } }" class="flex title-font font-medium items-center text-white mr-2 relative">
            <Brand size="normal" />
          </router-link>
        </div>
        <div>
          <router-link :to="{ name: 'Dashboard', params: { id: $store.state.Projects.idRoute } }" class="pt-[1.5rem] mx-1 px-2 inline-block cursor-pointer text-sm font-medium text-gray-500 focus:outline-none transition">
            <div class="flex-center space-x-2">
              <Icon icon="tabler:home" class="w-4 h-4" />
              <span>{{ $t("navbar.dashboard") }}</span>
            </div>
            <span class="transition mt-3.5 h-1 w-full block decorative rounded-t"></span>
          </router-link>
          <router-link v-if="$verifyPermission(permissions, ['v1.collection', 'v1.audience.keys', 'v2.client.project.show.segments']) && $verifyFeature($store.state.Projects.activeProject, 'segment')" :to="{ name: 'Audience', params: { id: $store.state.Projects.idRoute } }" class="pt-[1.5rem] mx-1 px-2 inline-block cursor-pointer text-sm font-medium text-gray-500 focus:outline-none transition">
            <div class="flex-center space-x-2">
              <Icon icon="tabler:users" class="w-4 h-4" />
              <span>{{ $t("navbar.audience") }}</span>
            </div>
            <span class="transition mt-3.5 h-1 w-full block decorative rounded-t"></span>
          </router-link>
          <!-- <router-link v-if="$store.state.Auth.user?.roles.some((role) => role == 'client_design')" :to="{ name: 'Design', params: { id: $store.state.Projects.idRoute } }" class="pt-[1.5rem] mx-1 px-2 inline-block cursor-pointer text-sm font-medium text-gray-500 focus:outline-none transition">
            <div class="flex-center space-x-2">
              <Icon icon="tabler:color-swatch" class="w-4 h-4" />
              <span>{{ $t("navbar.design") }}</span>
            </div>
            <span class="transition mt-3.5 h-1 w-full block decorative rounded-t"></span>
          </router-link> -->
          <router-link v-if="$verifyPermission(permissions, ['v2.client.template.index', 'v2.client.project.getAppearance']) && $verifyFeature($store.state.Projects.activeProject, 'design')" :to="{ name: 'Design', params: { id: $store.state.Projects.idRoute } }" class="pt-[1.5rem] mx-1 px-2 inline-block cursor-pointer text-sm font-medium text-gray-500 focus:outline-none transition">
            <div class="flex-center space-x-2">
              <Icon icon="tabler:color-swatch" class="w-4 h-4" />
              <span>{{ $t("navbar.design") }}</span>
            </div>
            <span class="transition mt-3.5 h-1 w-full block decorative rounded-t"></span>
          </router-link>
          <router-link v-if="$verifyPermission(permissions, ['v1.content.keys', 'v1.collection', 'v1.content.index'])" :to="{ name: 'Content', params: { id: $store.state.Projects.idRoute } }" class="pt-[1.5rem] mx-1 px-2 inline-block cursor-pointer text-sm font-medium text-gray-500 focus:outline-none transition">
            <div class="flex-center space-x-2">
              <Icon icon="tabler:list-details" class="w-4 h-4" />
              <span>{{ $t("navbar.content") }}</span>
            </div>
            <span class="transition mt-3.5 h-1 w-full block decorative rounded-t"></span>
          </router-link>
          <router-link v-if="$verifyPermission(permissions, ['v2.client.project_connection.index']) && $verifyFeature($store.state.Projects.activeProject, 'integration')" :to="{ name: 'Integrations', params: { id: $store.state.Projects.idRoute } }" class="pt-[1.5rem] mx-1 px-2 inline-block cursor-pointer text-sm font-medium text-gray-500 focus:outline-none transition">
            <div class="flex-center space-x-2">
              <Icon icon="tabler:plug" class="w-4 h-4" />
              <span>{{ $t("navbar.integrations") }}</span>
            </div>
            <span class="transition mt-3.5 h-1 w-full block decorative rounded-t"></span>
          </router-link>
          <router-link v-if="$verifyPermission(permissions, ['v2.client.report.index']) && $verifyFeature($store.state.Projects.activeProject, 'reports')" :to="{ name: 'Reports', params: { id: $store.state.Projects.idRoute } }" class="pt-[1.5rem] mx-1 px-2 inline-block cursor-pointer text-sm font-medium text-gray-500 focus:outline-none transition">
            <div class="flex-center space-x-2">
              <Icon icon="tabler:report" class="w-4 h-4" />
              <span>{{ $t("navbar.reports") }}</span>
            </div>
            <span class="transition mt-3.5 h-1 w-full block decorative rounded-t"></span>
          </router-link>
        </div>
      </div>
    </nav>
  </div>
  <div>
    <ul class="flex items-center HeaderMainNav">
      <li class="relative" v-click-away="closeProfileMenu">
        <button @click="toggleProfileMenu" class="items-center min-h-full group align-middle pt-3 mx-2 px-2 cursor-pointer text-sm text-gray-400 focus:outline-none hover:text-gray-100 transition" :class="[$store.state.Menu.isProfileMenuOpen ? 'open' : '']" aria-label="Account" aria-haspopup="true">
          <div class="flex items-center">
            <div class="avatar placeholder">
              <div class="bg-gray-300 text-gray-700 rounded-full w-8 h-8 inline md:mr-5 ring ring-white ring-offset-green-500 ring-offset-2" v-if="$store.state.Auth.user">
                <span><img :src="$store.state.Auth.user.avatar" class="rounded-full w-10" /></span>
              </div>
            </div>
            <div class="flex flex-col items-start">
              <p class="text-sm text-gray-700 group-hover:text-gray-600 hidden md:inline" v-if="$store.state.Auth.user">{{ $store.state.Auth.user.name }}</p>
              <p class="text-xs text-gray-500 hidden md:inline" v-if="$store.state.Projects.activeProject">
                <span>{{ $store.state.Projects.activeProject.company || $store.state.Projects.activeProject.name }}</span>
              </p>
            </div>
            <Icon icon="tabler:chevron-down" class="-mr-1 ml-2 h-5 w-5 text-sm hidden md:inline text-gray-400" />
          </div>
          <span class="decorative transition mt-2.5 h-1 w-full block rounded-t"></span>
        </button>
        <TransitionFade2>
          <ul v-if="$store.state.Menu.isProfileMenuOpen" class="transition-all z-10 ease-in-out absolute right-0 w-56 px-2 py-2 mt-2 space-y-2 text-gray-600 bg-white border border-gray-100 rounded-md shadow-md dark:border-gray-700 dark:text-gray-300 dark:bg-gray-700" aria-label="submenu">
            <li>
              <div class="block">
                <span class="text-gray-400 text-sm font-normal pt-2 pb-0 px-2 block">{{ $t("navbar.project") }}</span>
              </div>
            </li>
            <li v-if="$store.state.Projects.activeProject !== null" class="flex">
              <router-link :to="{ name: 'Project Team', params: { id: $store.state.Projects.idRoute } }" class="inline-flex items-center w-full px-2 py-2 text-sm font-normal transition-colors duration-150 rounded-md hover:bg-gray-100 hover:bg-opacity-80 hover:text-gray-800">
                <Icon icon="tabler:settings" class="w-4 h-4 mr-3" />
                <span>{{ $t("navbar.preferences") }}</span>
              </router-link>
            </li>
            <li v-if="$verifyPermission(permissions, ['v2.client.project.get.credentials', 'v2.client.project.create.credentials', 'v2.client.project.edit.credential'])" class="flex">
              <router-link :to="{ name: 'Channels', params: { id: $store.state.Projects.idRoute } }" class="inline-flex items-center w-full px-2 py-2 text-sm font-normal transition-colors duration-150 rounded-md hover:bg-gray-100 hover:bg-opacity-80 hover:text-gray-800">
                <Icon icon="tabler:key" class="w-4 h-4 mr-3" />
                <span>{{ $t("navbar.channels") }}</span>
              </router-link>
            </li>
            <li class="flex">
              <router-link :to="{ name: 'Integrations', params: { id: $store.state.Projects.idRoute } }" class="inline-flex items-center w-full px-2 py-2 text-sm font-normal transition-colors duration-150 rounded-md hover:bg-gray-100 hover:bg-opacity-80 hover:text-gray-800">
                <Icon icon="tabler:plug" class="w-4 h-4 mr-3" />
                <span>{{ $t("navbar.integrations") }}</span>
              </router-link>
            </li>
            <li class="flex">
              <router-link :to="{ name: 'Projects', params: { id: $store.state.Projects.idRoute } }" class="inline-flex items-center w-full px-2 py-2 text-sm font-normal transition-colors duration-150 rounded-md hover:bg-gray-100 hover:bg-opacity-80 hover:text-gray-800">
                <Icon icon="tabler:arrows-left-right" class="w-4 h-4 mr-3" />
                <span>{{ $t("navbar.switch") }}</span>
              </router-link>
            </li>
            <li class="flex">
              <router-link :to="{ name: 'Onboarding project', params: { id: $store.state.Projects.idRoute } }" class="inline-flex items-center w-full px-2 py-2 text-sm font-normal transition-colors duration-150 rounded-md hover:bg-gray-100 hover:bg-opacity-80 hover:text-gray-800">
                <Icon icon="tabler:plus" class="w-4 h-4 mr-3" />
                <span>{{ $t("navbar.new") }}</span>
              </router-link>
            </li>
            <li>
              <div class="border-b border-gray-200 my-2"></div>
            </li>
            <li>
              <div class="block">
                <span class="text-gray-400 text-sm font-normal pt-2 pb-0 px-2 block">{{ $t("navbar.user") }}</span>
              </div>
            </li>
            <li class="flex">
              <router-link :to="{ name: 'Profile', params: { id: $store.state.Projects.idRoute } }" class="inline-flex items-center w-full px-2 py-2 text-sm font-normal transition-colors duration-150 rounded-md hover:bg-gray-100 hover:bg-opacity-80 hover:text-gray-800">
                <Icon icon="tabler:settings" class="w-4 h-4 mr-3" />
                <span>{{ $t("navbar.preferences") }}</span>
              </router-link>
            </li>
            <li class="flex">
              <router-link :to="{ name: 'Invitations' }" class="inline-flex items-center w-full px-2 py-2 text-sm font-normal transition-colors duration-150 rounded-md hover:bg-gray-100 hover:bg-opacity-80 hover:text-gray-800">
                <Icon icon="tabler:mailbox" class="w-4 h-4 mr-3" />
                <span>{{ $t("invitations") }}</span>
              </router-link>
            </li>
            <li class="flex">
              <a target="_blank" :href="`https://help.setup.baymark.net/es/docs`" class="inline-flex items-center w-full px-2 py-2 text-sm font-normal transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200">
                <Icon icon="tabler:help" class="w-4 h-4 mr-3" />
                <span>{{ $t("navbar.help") }}</span>
              </a>
            </li>
            <li class="flex">
              <button @click="logOut" class="inline-flex items-center w-full px-2 py-2 text-sm font-normal transition-colors duration-150 rounded-md hover:bg-red-100 hover:bg-opacity-80 logout">
                <Icon icon="tabler:power" class="w-4 h-4 mr-3" />
                <span>{{ $t("navbar.logOut") }}</span>
              </button>
            </li>
            <div v-if="$store.state.Auth.user?.roles.some((role) => role == 'admin')">
              <li>
                <div class="border-b border-gray-200 my-2"></div>
              </li>
              <li>
                <div class="block">
                  <span class="text-gray-400 text-sm font-normal pt-2 pb-0 px-2 block">{{ $t("navbar.admin") }}</span>
                </div>
              </li>
              <li class="flex">
                <router-link :to="{ name: 'Admin' }" class="inline-flex items-center w-full px-2 py-2 text-sm font-normal transition-colors duration-150 rounded-md hover:bg-gray-100 hover:bg-opacity-80 hover:text-gray-800">
                  <Icon icon="tabler:settings" class="w-4 h-4 mr-3" />
                  <span>{{ $t("navbar.preferences") }}</span>
                </router-link>
              </li>
            </div>
          </ul>
        </TransitionFade2>
      </li>
    </ul>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue"
import Brand from "@/components/Brand.vue"
import TransitionFade2 from "@/components/TransitionFade2"
import { mapGetters } from 'vuex'

export default {
  name: "NavbarDesktop",

  components: {
    Icon,
    Brand,
    TransitionFade2
  },

  props: {
    navPro: {
      type: Boolean,
      required: false
    },
    navFree: {
      type: Boolean,
      required: false
    },
    navWithoutProject: {
      type: Boolean,
      required: false
    },
    dropdown: {
      type: Boolean,
      required: false
    }
  },

  computed: {
    ...mapGetters({permissions: 'Projects/permissionsForProject'})
  },

  data() {
    return {
      currentProject: this.$route.params.id ? this.$route.params.id : null
    }
  },

  watch: {
    "$route.params.id"() {
      this.currentProject = this.$route.params.id
    }
  },

  created() {
    if (this.currentProject) this.getProject(this.currentProject)
  },

  methods: {
    toggleProfileMenu() {
      if (this.$store.state.Menu.isProfileMenuOpen) {
        this.closeProfileMenu()
      } else {
        this.openProfileMenu()
      }
    },

    openProfileMenu() {
      this.$store.dispatch("Menu/openProfileMenu")
    },

    closeProfileMenu() {
      this.$store.dispatch("Menu/closeProfileMenu")
    },

    getProject(id) {
      this.$store.dispatch("Projects/getProject", id)
    },

    logOut() {
      this.$store.dispatch("Auth/requestLogout")
    }
  }
}
</script>
