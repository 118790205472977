const state = {
  isMenuLangsOpen: false,
  availablesLangs: ["en", "es", "pt"],
  selected: {
    name: "English",
    flag: "US",
    lang: "en"
  },
  availables: [
    {
      name: "English",
      flag: "US",
      lang: "en"
    },
    {
      name: "Español",
      flag: "ES",
      lang: "es"
    },
    {
      name: "Portugués",
      flag: "PT",
      lang: "pt"
    }
  ]
}

const mutations = {
  openMenuLangs(state) {
    state.isMenuLangsOpen = true
    console.log(state)
  },
  closeMenuLangs(state) {
    state.isMenuLangsOpen = false
  },
  changeAppLang(state, lang) {
    const i = state.availablesLangs.indexOf(lang)
    if (i > -1) {
      localStorage.setItem("language", JSON.stringify(state.availables[i]))
      state.selected = state.availables[i]
    }
  },
  initAppLang(state) {
    let ls_language = localStorage.getItem("language")
    let w_language = window.navigator.language ? window.navigator.language.substring(0, 2) : null

    if (ls_language) {
      try {
        state.selected = JSON.parse(ls_language)
      } catch (error) {
        // Silent error, fallback locale is ready.
        // console.log(error)
      }
    } else if (w_language) {
      let index = state.availablesLangs.indexOf(w_language)
      if (index > -1) {
        state.selected = state.availables[index]
      }
    }
  }
}

const actions = {
  openMenuLangs({ commit }) {
    commit("openMenuLangs")
  },
  closeMenuLangs({ commit }) {
    commit("closeMenuLangs")
  },
  changeAppLang({ commit }, lang) {
    commit("changeAppLang", lang)
    commit("closeMenuLangs")
  },
  initAppLang({ commit }) {
    commit("initAppLang")
  }
}

export const Langs = {
  namespaced: true,
  state,
  actions,
  mutations
}
