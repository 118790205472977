import { ProjectService } from '../services/AdminProjects'

const state = {
  loading: false,
  data: [],
  meta: {},
  links: {},
  authorized: true,
}

const actions = {
  async list ({ commit }, filter) {
    commit('resetState')
    commit('requestStart')
    let response = null
    try {
      response = await ProjectService.list(filter)
      commit("setData", response.data)
    } catch (error) {
      response = error.response
    }
    commit('requestEnd')
    return response
  },
  async show (aux, id) {
    let response = null
    try {
      response = await ProjectService.show(id)
    } catch (error) {
      response = error.response
    }
    return response
  },
  async store (aux, values) {
    let response = null
    try {
      response = await ProjectService.store(values)
    } catch (error) {
      response = error.response
    }
    return response
  },
  async update (aux, values) {
    let response = null
    try {
      response = await ProjectService.update(values.id, values)
    } catch (error) {
      response = error.response
    }
    return response
  },
  async enabled (aux, values) {
    let response = null
    try {
      response = await ProjectService.enabled(values.id, values.is_active)
    } catch (error) {
      response = error.response
    }
    return response
  },
  async remove (aux, id) {
    let response = null
    try {
      response = await ProjectService.remove(id)
    } catch (error) {
      response = error.response
    }
    return response
  },
  async UserAttach (aux, values) {
    let response = null
    try {
      response = await ProjectService.userAttach(values.id, values.userId, values.sendEmail)
    } catch (error) {
      response = error.response
    }
    return response
  },
  async UserDetach (aux, values) {
    let response = null
    try {
      response = await ProjectService.userDetach(values.id, values.userId)
    } catch (error) {
      response = error.response
    }
    return response
  },
  clearState ({ commit }) {
    commit('resetState')
  },
}

const mutations = {
  resetState (state) {
    state.loading = false
    state.data = []
    state.meta = {}
    state.links = {}
    state.authorized = true
  },
  requestStart(state) {
    state.loading = true
  },
  requestEnd(state) {
    state.loading = false
  },
  setData(state, data) {
    state.data = data.data
    state.meta = data.meta
    state.links = data.link
  },
  setAuthorized(state, authorized) {
    state.authorized = authorized
  },
}

export const AdminProjects = {
  namespaced: true,
  state,
  mutations,
  actions
}
