import axiosClient from "@/config/AxiosClient"
import AuthToken from "@/config/AuthToken"

export const AudienceServices = {
  getAudience(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    config.params = payload.filter
    const { currentProject } = payload
    return axiosClient.get(`/api/2.0/projects/${currentProject}/profiles`, config)
  },
  getAudienceKeys(currentProject) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    // return axiosClient.get(`api/2.0/segment/fields/${currentProject}`, config)
    return axiosClient.get(`api/1.0/projects/${currentProject}/audience/keys`, config)
  },
  getAudienceKeysFilter(currentProject) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    return axiosClient.get(`api/1.0/projects/${currentProject}/audience/keys/filter`, config)
  },
  storeSegment(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    const { segment } = payload
    const { currentProject } = payload
    return axiosClient.post(`/api/2.0/projects/${currentProject}/segment`, segment, config)
  },
  updateSegment(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    const { segment } = payload
    const { segmentId } = payload
    const { currentProject } = payload
    return axiosClient.put(`/api/2.0/projects/${currentProject}/segment/${segmentId}`, segment, config)
  },
  simulateSegment(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    const { segment } = payload
    const { currentProject } = payload
    return axiosClient.post(`/api/2.0/projects/${currentProject}/segment/simulate`, segment, config)
  },
  syncSegment(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    const { segment } = payload
    const { id } = payload
    return axiosClient.post(`/api/2.0/segment/${id}/sync`, segment, config)
  },
  getSegments(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    config.params = payload.filter
    const { currentProject } = payload
    return axiosClient.get(`/api/2.0/projects/${currentProject}/segments`, config)
  },
  showSegmentProfile(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    config.params = payload.filter
    const { id } = payload
    // const {currentProject} = payload
    return axiosClient.get(`/api/2.0/segments/${id}/profiles`, config)
  },
  showSegmentProfileWithEmail(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    const { value } = payload
    const { currentProject } = payload
    return axiosClient.get(`/api/1.0/projects/${currentProject}/audience/${value}/sale`, config)
  },
  deleteSegment(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    const { id } = payload
    const { currentProject } = payload
    return axiosClient.delete(`/api/1.0/projects/${currentProject}/segment/${id}`, config)
  },
  getLibrary() {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    return axiosClient.get(`/api/1.0/projects/segment/library`, config)
  },
  storeLibrary(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    const { currentProject } = payload
    const { id } = payload
    return axiosClient.post(`/api/1.0/projects/${currentProject}/segment/library/${id}`, config)
  },
  getCollection(currentProject) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    return axiosClient.get(`/api/1.0/projects/${currentProject}/collection`, config)
  },
  getChannels(currentProject) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    return axiosClient.get(`/api/1.0/projects/${currentProject}/audience/channels`, config)
  }
}
