import axiosClient from "@/config/AxiosClient"
import AuthToken from "@/config/AuthToken"

export const AuthServices = {
  logIn(payload) {
    const { email, password } = payload
    return axiosClient.post("/api/2.0/auth/login", { email, password })
  },
  sendEmailResetPassword(payload) {
    let url = `${process.env.VUE_APP_URL}/reset`
    const { email } = payload
    return axiosClient.post("/api/2.0/auth/send-reset-link", { email, url })
  },
  resetPassword(payload) {
    const { email, password, password_confirmation, token } = payload
    return axiosClient.post("/api/2.0/auth/reset-password", { email, password, password_confirmation, token })
  },
  updatePassword(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    return axiosClient.put("/api/2.0/auth/password", payload, config)
  },
  getUser() {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    return axiosClient.get("/api/2.0/auth/profile", config)
  }
}
