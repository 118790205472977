<template>
    <transition 
        enter-from-class="opacity-0"
        enter-active-class="transition-all duration-300"
        enter-to-class="opacity-100"
        leave-from-class="opacity-100"
        leave-active-class="transition-all duration-200"
        leave-to-class="opacity-0 "
        mode="out-in"
        >
        <slot></slot>
    </transition>
</template>

<script>
    export default {
        name: 'TransitionFade'
    }
</script>
