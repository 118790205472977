import { ContentServices } from "@/services/Content"

const state = {
  projectContent: [],
  projectContentLinks: [],
  projectContentMeta: [],
  projectContentKeys: [],
  projectContentCollection: [],
  total: null,
  isProjectContentActive: false,
  isProjectContentError: false,
  loading: false
}

const mutations = {
  loading(state) {
    state.loading = true
  },
  getContent(state, result) {
    state.projectContent = result.data
    state.projectContentLinks = result.links
    state.projectContentMeta = result.meta
    state.total = result.meta.total
    state.isProjectContentActive = true
    state.isProjectContentError = false
    state.loading = false
    // console.log(state)
  },
  getContentError(state) {
    state.projectContent = []
    state.projectContentLinks = []
    state.projectContentMeta = []
    state.total = null
    state.isProjectContentActive = false
    state.isProjectContentError = true
    state.loading = false
  },
  getContentKeys(state, keys) {
    state.projectContentKeys = keys
    // console.log(state)
  },
  resetContent(state) {
    state.projectContent = []
    state.projectContentLinks = []
    state.projectContentMeta = []
    state.projectContentKeys = []
    state.total = null
    state.isProjectContentActive = false
    state.isProjectContentError = false
    state.loading = false
  }
}

const actions = {
  async getContent({ commit }, payload) {
    commit("resetContent")
    commit("loading")
    let result = null
    try {
      result = await ContentServices.getContent(payload)
      commit("getContent", result.data)
    } catch (error) {
      result = error.response
      commit("getContentError")
    }
    return result
  },
  async getItemContent(aux, payload) {
    let result = null
    try {
      result = await ContentServices.getItemContent(payload)
    } catch (error) {
      result = error.response
    }
    return result
  },
  async getContentKeys({ commit }, currentProject) {
    let result = null
    try {
      result = await ContentServices.getContentKeys(currentProject)
      commit("getContentKeys", result.data)
    } catch (error) {
      result = error.response
    }
    return result
  },
  async storeCollection(aux, payload) {
    let result = null
    try {
      result = await ContentServices.storeCollection(payload)
      console.log(result)
    } catch (error) {
      result = error.response
    }
    return result
  },
  async deleteCollection(aux, payload) {
    let result = null
    try {
      result = await ContentServices.deleteCollection(payload)
    } catch (error) {
      result = error.response
    }
    return result
  },
  async updateCollection(aux, payload) {
    let result = null
    try {
      result = await ContentServices.updateCollection(payload)
    } catch (error) {
      result = error.response
    }
    return result
  }
}

export const Content = {
  namespaced: true,
  state,
  mutations,
  actions
}
