import { ProjectServices } from "@/services/Projects"
import ApiRoute from "@/config/ApiRoute"
import FormClient from "@/config/FormClient"
const sprintf = require("sprintf-js").sprintf

const state = {
  idRoute: "",
  defaultProject: null,
  activeProject: null,
  isPremiumProject: null,
  loading: false,

  projects: [],
  projectsLinks: [],
  projectsMeta: [],
  isProjectsActive: false,
  isProjectsError: false
}

const getters = {
  permissionsForProject: (state) => {
    return state.activeProject ? state.activeProject.permissions : []
  }
}

const mutations = {
  loading(state) {
    state.loading = true
  },
  loadingFalse(state) {
    state.loading = false
  },
  setDefaultProject(state, defaultProject) {
    state.defaultProject = defaultProject
    // console.log(state)
  },
  getProject(state, Project) {
    state.activeProject = Project.data
    state.idRoute = Project.data.id || ""
    ;(state.isPremiumProject = Project.data.is_premium), (state.loading = false)
    // console.log(state)
  },
  getProjects(state, data) {
    state.projects = data.data
    ;(state.isProjectsActive = true), (state.isProjectsError = false), (state.projectsLinks = "a")
    state.projectsMeta = "b"
    state.loading = false
    // console.log(state.projects)
  },
  resetProjects(state) {
    state.projects = []
    state.projectsLinks = []
    state.projectsMeta = []
    ;(state.isProjectsActive = false), (state.isProjectsError = false), (state.loading = false)
  },
  resetState(state) {
    state.defaultProject = null
    state.activeProject = null
    state.loading = false
  }
}
const actions = {
  setDefaultProject({ commit }, payload) {
    commit("setDefaultProject", payload)
  },
  async getProject({ commit }, id) {
    let result = null
    try {
      result = await ProjectServices.getProject(id)
      let permissions = await new FormClient().get(sprintf(ApiRoute.project.permission, { project_id: id }));
      result.data.data.permissions = permissions.data
      await ProjectServices.storeProjectHistory(id)
      commit("getProject", result.data)
    } catch (error) {
      console.log(error.response)
    }
    return result
  },
  async getProjects({ commit }, payload) {
    let result = null
    commit("resetProjects")
    commit("loading")
    try {
      result = await ProjectServices.getProjects(payload)
      commit("getProjects", result.data)
    } catch (error) {
      commit("loadingFalse")
    }
    return result
  },
  async storeProject({ commit }, Project) {
    let result = null
    commit("loading")
    try {
      result = await ProjectServices.storeProject(Project)
      // console.log(result)
    } catch (error) {
      result = error.response
    }
    commit("loadingFalse")
    return result
  }
}

export const Projects = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
