import { ChannelServices } from "@/services/Channels"

const state = {
  channels: [],
  channelsError: [],
  channelsSuccess: [],
  channelsMeta: [],
  channelsLinks: [],
  isChannelsActive: false,

  channel: {},
  credentials: {},

  loading: false
}
const mutations = {
  loading(state) {
    state.loading = true
  },
  loadingFalse(state) {
    state.loading = false
  },
  getChannelsSucces(state, data) {
    state.channels = data.data
    state.channelsError = data.data.filter((app) => app.is_active === false)
    state.channelsSuccess = data.data.filter((app) => app.is_active === true)
    state.channelsMeta = data.meta
    state.channelsLinks = data.links
    state.isChannelsActive = true
    state.loading = false
  },
  resetChannels(state) {
    state.channels = []
    state.channelsMeta = []
    state.channelsLinks = []
    state.isChannelsActive = false
    state.loading = false
  },
  getChannel(state, channel) {
    state.channel = channel.data
  },
  resetChannel(state) {
    state.channel = []
  }
}
const actions = {
  async getChannels({ commit }, payload) {
    let result = null
    commit("resetChannels")
    commit("loading")
    try {
      if (payload) {
        payload.filter.include = "services.service_config,services_output.service_config,services_segment"
        payload.filter["filter[is_active][=]"] = 1
      } else {
        payload = {
          filter: { include: "services.service_config,services_output.service_config,services_segment" }
        }
        payload.filter["filter[is_active][=]"] = 1
      }
      result = await ChannelServices.getChannels(payload)
      commit("getChannelsSucces", result.data)
    } catch (error) {
      console.log(error)
      result = error.response
    }
    return result
  },
  async getChannel({ commit }, payload) {
    let result = null
    commit("resetChannel")
    commit("loading")
    try {
      result = await ChannelServices.getChannel(payload)
      commit("getChannel", result.data)
    } catch (error) {
      result = error.response
    }
    return result
  }
}

export const Channels = {
  namespaced: true,
  state,
  mutations,
  actions
}
