<template>
  <div class="h-full flex flex-col" :class="[$route.meta.isLayoutFull ? '' : 'overflow-y-auto']">
    <TransitionFade>
      <Loader v-if="loadingGeneral" class="w-full h-full" />
      <div class="h-full flex flex-col flex-1" v-else>
        <Invite v-if="false" />
        <Online :condition="isStatusUserShow == 'Online'" />
        <Offline :condition="isStatusUserShow == 'Offline'" />
        <ConfirmDialog group="confirm" :breakpoints="{ '640px': '100vw' }" :style="{ width: '20vw' }"></ConfirmDialog>

        <TransitionFade>
          <div v-if="!$route.meta.plainLayout">
            <div class="headerAside">
              <transition enter-from-class="opacity-0 invisible" enter-active-class="transition-all duration-200" enter-to-class="opacity-100 visible" leave-from-class="opacity-100 visible" leave-active-class="transition-all delay-200 duration-200" leave-to-class="opacity-0 invisible" mode="out-in">
                <div class="transition-all ease-in-out fixed inset-0 z-10 flex items-end bg-white bg-opacity-50 sm:items-center sm:justify-center md:hidden" v-if="$store.state.Menu.isSideMenuOpen"></div>
              </transition>
              <transition enter-from-class="-ml-72" enter-active-class="transition-all delay-150 duration-200" enter-to-class="ml-0" leave-from-class="ml-0" leave-active-class="transition-all duration-200" leave-to-class="-ml-72" mode="out-in">
                <div class="transition-all ease-in-out fixed inset-y-0 z-20 flex-shrink-0 w-64 overflow-y-auto bg-white md:hidden" v-if="$store.state.Menu.isSideMenuOpen" v-click-away="closeSideMenu">
                  <div class="py-4 text-gray-500">
                    <!-- Navbar Mobile -->
                    <NavbarMobile :navPro="true" />
                  </div>
                </div>
              </transition>
            </div>
            <div class="header">
              <header class="text-gray-600 body-font bg-white shadow border-t-2 border-green-500 h-16">
                <div class="container-fluid">
                  <div class="flex-center justify-between">
                    <nav class="md:hidden flex flex-wrap items-center text-base" v-if="$store.state.Projects.isPremiumProject == 1 || $store.state.Projects.isPremiumProject == 0">
                      <a id="buttonSideMenu" class="py-4 mx-2 px-2 inline-block cursor-pointer text-sm text-gray-400 focus:outline-none border-b-4 border-transparent hover:border-gray-300 hover:text-gray-100 transition" @click="openSideMenu">
                        <svg class="inline align-top" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" fit="" height="27" width="27" preserveAspectRatio="xMidYMid meet" focusable="false"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
                      </a>
                    </nav>
                    <!-- Navbars desktop -->
                    <NavbarAdmin v-if="$store.state.User.showAdminNav" :navPro="true" dropdown />
                    <NavbarDesktop v-else :navPro="true" dropdown />
                  </div>
                </div>
              </header>
            </div>
          </div>
        </TransitionFade>
        <router-view v-if="access" v-slot="slotProps">
          <component :is="slotProps.Component"></component>
        </router-view>
        <NotAccess v-else />
      </div>
    </TransitionFade>
  </div>
</template>

<script>
import TransitionFade from "@/components/TransitionFade"
import TransitionFade2 from "@/components/TransitionFade2"
import Invite from "./components/Invite"
import Online from "@/components/Utils/Online"
import Offline from "@/components/Utils/Offline"
import NavbarDesktop from "@/components/Navbars/NavbarDesktop"
import NavbarMobile from "@/components/Navbars/NavbarMobile"
import ConfirmDialog from "primevue/confirmdialog"
import NavbarAdmin from "@/components/Admin/NavbarAdmin"
import NotAccess from '@/views/NotAccess';
import Loader from '@/components/UI/Loader';
import { mapGetters } from 'vuex'

export default {
  name: "App",
  components: {
    Invite,
    TransitionFade,
    TransitionFade2,
    Online,
    Offline,
    NavbarDesktop,
    NavbarMobile,
    ConfirmDialog,
    NavbarAdmin,
    NotAccess,
    Loader
  },

  data: function () {
    return {
      switchProjectModal: false,
      redirectToDashboard: false,
      currentProject: this.$route.params.id ? this.$route.params.id : null,
      isOnLine: null,
      isStatusUserShow: "",
      loadingGeneral: false,
    }
  },
  computed: {
    ...mapGetters({permissions: 'Projects/permissionsForProject'}),
    verifyAdminNav() {
      return this.$store.state.User.showAdminNav
    },
    access() {
      let verifyPermission = this.$route.meta.permissions ? this.$verifyPermission(this.$store.getters['Projects/permissionsForProject'], this.$route.meta.permissions) : true;
      let verifyFeature = this.$route.meta.feature ? this.$verifyFeature(this.$store.state.Projects.activeProject, this.$route.meta.feature) : true
      
      return verifyPermission && verifyFeature
    }
  },

  mounted() {
    window.addEventListener("online", () => {
      this.isOnLine = true
    })
    window.addEventListener("offline", () => {
      this.isOnLine = false
    })
  },

  watch: {
    "$route.params.id"() {
      this.currentProject = this.$route.params.id
    },
    isOnLine: {
      handler(newVal, oldVal) {
        if (!newVal) {
          this.isStatusUserShow = "Offline"
        }
        if (!oldVal && newVal) {
          this.isStatusUserShow = "Online"
          setTimeout(() => {
            this.isStatusUserShow = ""
          }, 2000)
        }
      }
    }
  },

  async beforeUpdate(){
    await this.verifyCurrentProject()
  },

  async created() {
    await this.verifyCurrentProject()
    this.$i18n.locale = this.$store.state.Langs.selected.lang
  },

  methods: {
    openSideMenu: function () {
      this.$store.dispatch("Menu/openSideMenu")
    },

    closeSideMenu: function (event) {
      let ignore = false
      let ignoreIds = ["buttonSideMenu"]
      event.path.forEach((p) => {
        if (p.id && p.id.indexOf(ignoreIds) > -1) {
          ignore = true
        }
      })

      if (!ignore) {
        this.$store.dispatch("Menu/closeSideMenu")
      }
    },

    closeProfileMenu: function () {
      this.$store.dispatch("Menu/closeProfileMenu")
    },

    async getProject(id) {
      await this.$store.dispatch("Projects/getProject", id)
    },
    async verifyCurrentProject(){
      if (this.currentProject && !this.permissions.length){
        this.loadingGeneral = true
        await this.getProject(this.currentProject)
        this.loadingGeneral = false
      }
    }
  }
}
</script>
