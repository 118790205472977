const state = {
    account: {},
}

const mutations = {
    setAccount(state, account) {
        state.account = account
        localStorage.setItem('account', JSON.stringify(account))
    }
}

const actions = {
    //
}

export const Accounts ={
    namespaced: true,
    state,
    actions,
    mutations
}