import axiosClient from '../config/AxiosClient'

export const AdminLogIntegrationService = {
    list,
}

const accessToken = localStorage.getItem('accessToken');
const headers = {
    headers: {
        "Authorization": `Bearer ${accessToken}`
    }
}

function list(filter, integration_id){
    return axiosClient.get(`api/2.0/admin/integration/${integration_id}/logs`, {...headers, params: filter});
}