import { AdminLogIntegrationService } from "@/services/AdminLogIntegration"

const state = {
    loading: false,
    data: [],
    meta: {},
    links: {},
    authorized: true,
    title: '',
}

const actions = {
    async list({commit}, params){
        commit('requestStart');
        try {
            let response = await AdminLogIntegrationService.list(params.filter, params.integration_id);
            commit('setData', response.data);
        } catch (error) {
            if (error.toString().includes("403")) {
                commit('setAuthorized', false)
            }
        }
        commit('requestEnd');
    },
}

const mutations = {
    requestStart(state){
        state.loading = true;
    },
    requestEnd(state){
        state.loading = false;
    },
    setAuthorized(state, authorized){
        state.authorized = authorized;
    },
    setData(state, data){
        state.data = [...data.data];
        state.meta = {...data.meta};
        state.links = {...data.link};
        state.title = `${data.data[0].integration.project.name} - ${data.data[0].integration.channel.name} ${data.data[0].integration.service.name}`
    },
}

export const AdminLogIntegration = {
    namespaced: true,
    state,
    actions,
    mutations
}