import axiosClient from '../config/AxiosClient'

export const AdminJobIntegrationService = {
    list,
}

function list(filter) {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
        headers: {
            "Authorization": `Bearer ${accessToken}`
        }
    }
    return axiosClient.get(`/api/2.0/admin/integration/jobs`, {...headers, params: filter})
}