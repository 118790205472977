import {createStore} from 'vuex'
import {Menu} from './Menu'
import {Auth} from './Auth'
import {User} from './User'
import {Langs} from './Langs'
import {Projects} from './Projects'
import {Channels} from './Channels'
import {Content} from './Content'
import {Audience} from './Audience'
import {Dashboard} from './Dashboard'
import {Calendar} from './Calendar'
import {Integrations} from './Integrations'
import {BasicSegment} from './BasicSegment'
import {Utilities} from './Utilities'
import {Accounts} from './Accounts'
import {AdminProjects} from './AdminProjects'
import {AdminUsers} from './AdminUsers'
import {AdminIntegration} from './AdminIntegration'
import {AdminJobIntegration} from './AdminJobIntegration'
import {AdminLogIntegration} from './AdminLogIntegration'

export default createStore({
  state: {

  },
  mutations: {

  },
  actions: {

  },
  modules: {
    Menu,
    Auth,
    User,
    Langs,
    Projects,
    Channels,
    Content,
    Audience,
    Dashboard,
    Calendar,
    Integrations,
    BasicSegment,
    Utilities,
    Accounts,
    AdminProjects,
    AdminUsers,
    AdminIntegration,
    AdminJobIntegration,
    AdminLogIntegration
  }
})
