<template>
  <div class="md:hidden flex flex-wrap justify-center items-center text-base">
    <router-link :to="{ name: 'Dashboard', params: { id: $store.state.Projects.idRoute } }" class="flex title-font font-medium items-center text-white mx-auto md:mx-0 relative">
      <Brand size="normal" />
    </router-link>
  </div>
  <div>
    <ul class="mt-6 HeaderAsideNav">
      <li class="relative">
        <router-link :to="{ name: 'Dashboard', params: { id: $store.state.Projects.idRoute } }" class="inline-flex items-center w-full text-sm font-medium hover:text-gray-800 active:bg-gray-300 py-3 pl-4 transition-colors">
          <span class="decorative"></span>
          <Icon icon="tabler:home" class="w-5 h-5" />
          <span class="ml-4">{{ $t("navbar.dashboard") }}</span>
        </router-link>
      </li>
      <li class="relative">
        <router-link :to="{ name: 'Audience', params: { id: $store.state.Projects.idRoute } }" class="inline-flex items-center w-full text-sm font-medium hover:text-gray-800 active:bg-gray-300 py-3 pl-4 transition-colors">
          <span class="decorative"></span>
          <Icon icon="tabler:users" class="w-5 h-5" />
          <span class="ml-4">{{ $t("navbar.audience") }}</span>
        </router-link>
      </li>
      <li class="relative">
        <router-link v-if="$store.state.Auth.user?.roles.some((role) => role == 'client_design')" :to="{ name: 'Design', params: { id: $store.state.Projects.idRoute } }" class="inline-flex items-center w-full text-sm font-medium hover:text-gray-800 active:bg-gray-300 py-3 pl-4 transition-colors">
          <span class="decorative"></span>
          <Icon icon="tabler:color-swatch" class="w-5 h-5" />
          <span class="ml-4">{{ $t("navbar.design") }}</span>
        </router-link>
      </li>
      <li class="relative">
        <router-link :to="{ name: 'Content', params: { id: $store.state.Projects.idRoute } }" class="inline-flex items-center w-full text-sm font-medium hover:text-gray-800 active:bg-gray-300 py-3 pl-4 transition-colors">
          <span class="decorative"></span>
          <Icon icon="tabler:list-details" class="w-5 h-5" />
          <span class="ml-4">{{ $t("navbar.content") }}</span>
        </router-link>
      </li>
      <li class="relative">
        <router-link :to="{ name: 'Integrations', params: { id: $store.state.Projects.idRoute } }" class="inline-flex items-center w-full text-sm font-medium hover:text-gray-800 active:bg-gray-300 py-3 pl-4 transition-colors">
          <span class="decorative"></span>
          <Icon icon="tabler:plug" class="w-5 h-5" />
          <span class="ml-4">{{ $t("navbar.integrations") }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue"
import Brand from "@/components/Brand.vue"

export default {
  name: "NavbarMobile",

  components: {
    Icon,
    Brand
  },

  props: {
    navPro: {
      type: Boolean,
      required: false
    },
    navFree: {
      type: Boolean,
      required: false
    },
    navWithoutProject: {
      type: Boolean,
      required: false
    }
  },

  data() {
    return {
      currentProject: this.$route.params.id ? this.$route.params.id : null
    }
  },

  watch: {
    "$route.params.id"() {
      this.currentProject = this.$route.params.id
    }
  },

  created() {
    if (this.currentProject) this.getProject(this.currentProject)
  },

  methods: {
    openProfileMenu() {
      this.$store.dispatch("Menu/openProfileMenu")
    },

    closeProfileMenu() {
      this.$store.dispatch("Menu/closeProfileMenu")
    },

    openNotificationPanel() {
      this.$store.dispatch("Menu/openNotificationPanel")
    },

    closeNotificationPanel() {
      this.$store.dispatch("Menu/closeNotificationPanel")
    },

    getProject(id) {
      this.$store.dispatch("Projects/getProject", id)
    },

    logOut() {
      this.$store.dispatch("Auth/requestLogout")
    }
  }
}
</script>
