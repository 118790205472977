import { AuthServices } from "@/services/Auth"
import router from "../router"
import axiosClient from "@/config/AxiosClient"

const state = {
  isLoginSuccess: false,
  isLoginError: false,
  messageSuccess: "",
  messageError: "",
  loading: false,
  forgot: false,
  token: null,
  user: null,
  userProjects: null,
  externalLogin: false
}

const mutations = {
  loading(state) {
    state.loading = true
  },
  loadingFalse(state) {
    state.loading = false
  },
  loginSuccess(state, user) {
    state.isLoginSuccess = true
    state.isLoginError = false
    state.messageSuccess = "Credenciales correctas, redireccionando.."
    state.messageError = ""
    state.loading = false
    state.forgot = false
    state.token = null
    state.user = user
    // console.log(state)
  },
  loginError(state, { message }) {
    state.isLoginSuccess = false
    state.isLoginError = true
    state.messageSuccess = ""
    state.messageError = message || "Credenciales incorrectas, intente nuevamente"
    state.loading = false
    state.forgot = false
    state.token = null
    state.user = null
    // console.log(state)
  },
  reset(state) {
    state.isLoginSuccess = false
    state.isLoginError = false
    state.messageSuccess = ""
    state.messageError = ""
    state.loading = false
    state.forgot = false
    state.token = null
    state.user = null
    state.userProjects = null
    // console.log(state)
  },
  CurrentUser(state, { data }) {
    state.user = data
    state.userProjects = data.projects
    // console.log(state)
  },
  setAccessTokenStore(state, Token) {
    state.token = Token
    axiosClient.defaults.headers.common["Authorization"] = `Bearer ${Token} `
  },
  setExternalLogin(state, externalLogin) {
    state.externalLogin = externalLogin
    localStorage.setItem("externalLogin", externalLogin)
  }
}

const actions = {
  async requestLogIn({ commit }, payload) {
    let result = null
    commit("reset")
    commit("loading")
    try {
      result = await AuthServices.logIn(payload)
    } catch (error) {
      result = error.response
    }
    commit("loadingFalse")
    return result
  },
  loginSuccess({ commit }) {
    commit("reset")
    commit("loginSuccess")
  },
  loginError({ commit }, message) {
    commit("reset")
    commit("loginError", message)
  },
  requestLogout({ commit }) {
    localStorage.removeItem("Token")
    router.push("/login")
    commit("reset")
  },
  async requestResetPassword({ commit }, payload) {
    let result = null
    commit("reset")
    commit("loading")
    try {
      result = await AuthServices.sendEmailResetPassword(payload)
    } catch (error) {
      result = error.response
    }
    commit("loadingFalse")
    return result
  },
  async resetPassword({ commit }, payload) {
    let result = null
    commit("reset")
    commit("loading")
    try {
      result = await AuthServices.resetPassword(payload)
    } catch (error) {
      result = error.response
    }
    commit("loadingFalse")
    return result
  },
  async updatePassword({ commit }, payload) {
    commit("loading")
    let result = null
    try {
      result = await AuthServices.updatePassword(payload)
    } catch (error) {
      result = error.response
    }
    commit("loadingFalse")
    return result
  },
  async sessionStart({ commit, dispatch, state }) {
    if (state.user) {
      return true
    }
    let getAccessTokenResult = await dispatch("getAccessToken")
    if (getAccessTokenResult) {
      await dispatch("getUser")
      return true
    } else {
      await commit("reset")
      return false
    }
  },
  getAccessToken({ dispatch }) {
    let Token = localStorage.getItem("Token")
    if (Token) {
      dispatch("setTokenLocalStorage", Token)
      return true
    }
    return false
  },
  setTokenLocalStorage({ commit }, Token) {
    localStorage.setItem("Token", Token)
    commit("setAccessTokenStore", Token)
  },
  async getUser({ commit }) {
    let result = null
    try {
      result = await AuthServices.getUser()
      commit("CurrentUser", result.data)
    } catch (error) {
      console.log(error.response)
    }
    return result
  },
  setToken({ commit }, token) {
    localStorage.setItem("Token", token)
    commit("setAccessTokenStore", token)
  },
  setUser({ commit }, user) {
    commit("CurrentUser", user)
  }
}

export const Auth = {
  namespaced: true,
  state,
  actions,
  mutations
}
