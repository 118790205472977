import axiosClient from '../config/AxiosClient'

export const AdminIntegrationService = {
  list,
  startIntegration,
  validateFrom,
  validateTo,
  requestList,
  requestGroups,
  config,
  autoMapper,
  frequency,
  changeProjectConnection,
  changeIntegration,
  show
}

function list (filter) {
  let accessToken = localStorage.getItem('accessToken')
  let config = {
    headers: {
      "Authorization": `Bearer ${accessToken}`
    },
    params: filter
  }

  return axiosClient.get('api/2.0/admin/project/connection', config)
}

function startIntegration(payload) {
  let accessToken = localStorage.getItem('accessToken')
  let config = {
    headers: {
      "Authorization": `Bearer ${accessToken}`
    },
  }
  return axiosClient.post(`/api/2.0/integrations`, payload, config)
}

function validateFrom(payload) {
  let accessToken = localStorage.getItem('accessToken')
  let config = {
    headers: {
      "Authorization": `Bearer ${accessToken}`
    },
  }
  return axiosClient.post(`/api/2.0/integrations/check-services/from`, payload, config)
}

function validateTo(payload) {
  let accessToken = localStorage.getItem('accessToken')
  let config = {
    headers: {
      "Authorization": `Bearer ${accessToken}`
    },
  }
  return axiosClient.post(`/api/2.0/integrations/check-services/to`, payload, config)
}

function config(payload) {
  let accessToken = localStorage.getItem('accessToken')
  let config = {
    headers: {
      "Authorization": `Bearer ${accessToken}`
    },
  }
  const { id } = payload
  const { data } = payload
  return axiosClient.put(`/api/2.0/integrations/${id}/config`, data, config)
}

function requestList(payload) {
  let accessToken = localStorage.getItem('accessToken')
  let config = {
    headers: {
      "Authorization": `Bearer ${accessToken}`
    },
  }
  return axiosClient.post(`/api/2.0/integrations/mailup/lists`, payload, config)
}

function requestGroups(payload) {
  let accessToken = localStorage.getItem('accessToken')
  let config = {
    headers: {
      "Authorization": `Bearer ${accessToken}`
    },
  }
  const {id} = payload
  return axiosClient.post(`/api/2.0/integrations/mailup/lists/${id}/groups`, payload, config)
}

function frequency() {
  let accessToken = localStorage.getItem('accessToken')
  let config = {
    headers: {
      "Authorization": `Bearer ${accessToken}`
    },
  }
  return axiosClient.get(`/api/2.0/utilities/schedule-frequency`, config)
}

function autoMapper(payload) {
  let accessToken = localStorage.getItem('accessToken')
  let config = {
    headers: {
      "Authorization": `Bearer ${accessToken}`
    },
  }
  const { integrationId } = payload
  const { object } = payload
  return axiosClient.put(`/api/2.0/integrations/${integrationId}/automapper`, object, config)
}

function changeProjectConnection(parameters){
    let accessToken = localStorage.getItem('accessToken')
    let config = {
        headers: {
            "Authorization": `Bearer ${accessToken}`
        },
        params: parameters
    }
    const { id } = parameters
    return axiosClient.get(`/api/2.0/admin/project/connection/${id}/change`, config)
}

function changeIntegration(parameters){
    let accessToken = localStorage.getItem('accessToken')
    let config = {
        headers: {
            "Authorization": `Bearer ${accessToken}`
        },
        params: parameters
    }
    const { id } = parameters
    return axiosClient.get(`/api/2.0/admin/integration/${id}/change`, config)
}

function show(parameters){
    let accessToken = localStorage.getItem('accessToken')
    let config = {
        headers: {
            "Authorization": `Bearer ${accessToken}`
        },
        params: parameters
    }
    const { id } = parameters
    return axiosClient.get(`/api/2.0/admin/project/connection/${id}`, config)
}

