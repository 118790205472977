import { IntegrationServices } from "@/services/Integrations"

const state = {
  show: null,
  loading: false,

  initData: null,
  fromData: [],
  fromDataError: [],

  services: [],
  toData: null,
  toDataError: [],

  listsData: [],
  loadingLists: false,
  groupsData: [],

  autoMapper: null,
  finish: null,
  integrationsSelectedToFinish: [],
  frequency: null,
  project_connection_edit: {}
}

const mutations = {
  loading(state) {
    state.loading = true
  },
  loadingError(state) {
    state.loading = false
  },
  setShow(state, data) {
    state.show = data
    // console.log(state)
  },
  resetShow(state) {
    state.show = null
  },
  setInitData(state, data) {
    state.initData = data
    state.loading = false
    // console.log(state)
  },
  setToData(state, data) {
    state.toData = data
    // console.log(state)
  },
  integrationsSelectedToFinish(state, data) {
    state.integrationsSelectedToFinish = data
    // console.log(state)
  },
  setAutoMapper(state, data) {
    state.autoMapper = data
    state.loading = false
    // console.log(state)
  },
  setFinish(state, data) {
    state.finish = data
    state.loading = false
    // console.log(state)
  },
  resetInitData(state) {
    state.initData = null
  },
  resetToData(state) {
    state.toData = null
  },
  resetAutoMapper(state) {
    state.autoMapper = null
  },
  resetFinish(state) {
    state.finish = null
  },
  resetState(state) {
    state.show = null
    state.loading = false
    state.initData = null
    state.fromData = []
    state.fromDataError = []
    state.services = []
    state.toData = null
    state.autoMapper = null
    state.finish = null
    state.integrationsSelectedToFinish = []
    state.project_connection_edit = {}
    state.frequency = null
  },
  setListData(state, data) {
    state.listsData = data
  },
  resetListData(state) {
    state.listsData = []
  },
  setGroupsData(state, data) {
    state.groupsData = data
  },
  resetGroupsData(state) {
    state.groupsData = []
  },
  setFrequency(state) {
    state.frequency = []
  },
  setConfig(state) {
    state.integrationPending = Integrations.data.state == "CONFIG"
  },
  setProjectConnectionEdit(state, data) {
    state.project_connection_edit = data
    localStorage.setItem("project_connection_edit", JSON.stringify(data))
  },
  getProjectConnectionEdit(state) {
    let project_connection_edit = JSON.parse(localStorage.getItem("project_connection_edit"))
    state.project_connection_edit = project_connection_edit ?? {}
  }
}

const actions = {
  async show({ commit }, currentProcessId) {
    let result = null
    commit("resetShow")
    commit("loading")
    try {
      result = await IntegrationServices.Show(currentProcessId)
      commit("setShow", result.data)
    } catch (error) {
      result = error.response
    }
    commit("loadingError")
    return result
  },
  async setInitData({ commit }, payload) {
    let result = null
    commit("resetInitData")
    commit("loading")
    try {
      result = await IntegrationServices.ProjectInit(payload)
      commit("setInitData", result.data)
    } catch (error) {
      result = error.response
    }
    commit("loadingError")
    return result
  },
  async setFromData({ commit, dispatch }, payload) {
    let data = {}
    data.service_id = payload.service_id

    let fromData = null
    commit("loading")
    fromData = await IntegrationServices.validateFrom(payload)
    data.fields = fromData.data.fields ? fromData.data.fields : fromData.data
    dispatch("acumulateFromData", data)

    commit("loadingError")
    return fromData
  },
  acumulateFromData({ state, dispatch }, prop) {
    state.fromData = [...state.fromData, prop]
    dispatch("saveFieldsLocalStorageFrom", state.fromData)
  },
  acumulateFromDataErrors({ state }, prop) {
    state.fromDataError = [...state.fromDataError, prop]
  },
  saveFieldsLocalStorageFrom(aux, prop) {
    localStorage.setItem("fromData", JSON.stringify(prop))
  },
  acumulateServices({ state, dispatch }, prop) {
    state.services = [...state.services, prop]
    dispatch("saveServicesLocalStorage", state.services)
  },
  saveServicesLocalStorage(aux, prop) {
    localStorage.setItem("services", JSON.stringify(prop))
  },
  async requestLists({ dispatch }, payload) {
    let lists = null
    try {
      lists = await IntegrationServices.requestList(payload)
      dispatch("saveListData", lists.data)
    } catch (error) {
      lists = error.response
    }

    return lists
  },
  saveListData(aux, lists) {
    localStorage.setItem("lists", JSON.stringify(lists))
  },
  async requestGroups({ commit }, payload) {
    commit("resetGroupsData")
    // commit('loading')
    let groups = null
    try {
      groups = await IntegrationServices.requestGroups(payload)
      commit("setGroupsData", groups.data)
    } catch (error) {
      groups = error.response
    }
    // commit('loadingError')
    return groups
  },
  async setToData({ commit, dispatch }, payload) {
    let toData = null
    try {
      toData = await IntegrationServices.validateTo(payload)
      commit("setToData", toData.data)
      dispatch("saveFieldsLocalStorageTo", toData.data)
    } catch (error) {
      toData = error.response
    }
    return toData
  },
  acumulateToDataErrors({ state }, prop) {
    state.toDataError = [...state.toDataError, prop]
  },
  saveFieldsLocalStorageTo(aux, toData) {
    localStorage.setItem("toData", JSON.stringify(toData))
  },
  async confirmConfig({ commit }, payload) {
    let config = null
    commit("loading")
    try {
      config = await IntegrationServices.Config(payload)
    } catch (error) {
      config = error.response
    }
    commit("loadingError")
    return config
  },
  integrationsSelectedToFinish({ commit }, payload) {
    commit("integrationsSelectedToFinish", payload)
  },
  async autoMapper({ commit }, payload) {
    let AutoMapper = null
    commit("resetAutoMapper")
    commit("loading")
    try {
      AutoMapper = await IntegrationServices.autoMapper(payload)
      commit("setAutoMapper", AutoMapper)
    } catch (error) {
      AutoMapper = error.response
    }

    commit("loadingError")
    return AutoMapper
  },
  // async finish({ commit }, frequencyToFinish) {

  //   let Finish = null
  //   commit('resetFinish')
  //   commit('loading')
  //   try {
  //     Finish = await IntegrationServices.finish(frequencyToFinish)
  //     commit('setFinish', Finish)
  //   } catch (error) {
  //     Finish = error.response
  //   }
  //   commit('loadingError')
  //   return Finish

  // },
  async frequency({ commit }) {
    let result = null
    try {
      result = await IntegrationServices.frequency()
      commit("setFrequency", result.data.data)
      localStorage.setItem("shcedule-frequency", JSON.stringify(result.data.data))
    } catch (error) {
      result = error.response
    }
    return result
  },
  resetIntegrationState({ commit }) {
    commit("resetState")
    localStorage.removeItem("services")
    localStorage.removeItem("fromData")
    localStorage.removeItem("toData")
    localStorage.removeItem("lists")
    localStorage.removeItem("integrationsCredentials")
    localStorage.removeItem("authMailup")
    localStorage.removeItem("shcedule-frequency")
    localStorage.removeItem("integrationsCredentialsOAuth2")
    localStorage.removeItem("integrationIdInitialized")
    localStorage.removeItem("project_connection_edit")
  }
}

export const Integrations = {
  namespaced: true,
  state,
  actions,
  mutations
}
