import axios from "axios"

const instance = axios.create({
  baseURL: process.env.VUE_APP_URL_API,
  headers: {
    "Content-Type": "application/json"
  },
  timeout: 480000 // Eight minutes
})

instance.interceptors.request.use((config) => {
  config.headers["Authorization"] = "Bearer " + localStorage.getItem("Token")
  return config
})

export default instance
