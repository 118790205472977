<template>
  <transition
        enter-from-class="transform opacity-0 scale-95"
        enter-active-class="transition ease-out duration-100"
        enter-to-class="transform opacity-100 scale-100"
        leave-from-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-to-class="transform opacity-0 scale-95"
        mode="out-in"
    >
    <div class="fixed bg-gray-600 rounded-lg w-full md:w-96 h-12 bottom-1 md:top-20 z-40 inset-x-2.5 mx-auto shadow" v-if="condition">
      <div class="inline-flex items-center h-full">
        <div class="w-16 flex flex-col items-center justify-center ">
          <div class="h-4 w-4 rounded-full bg-green-500"></div>
        </div>
        <div class="w-80 font-semibold text-white ">
          {{ t("utils.online", {}, { locale: $store.state.Langs.selected.lang }) }}
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { useI18n } from "vue-i18n"
  export default {
    name: 'Online',
    props: ['condition'],
    setup() {
      const { t, locale } = useI18n()
      return { t, locale }
    }
  }
</script>

<style lang="scss" scoped>

</style>
