const state = {
  isDatesSelected:null,
  isChannelSelected: null,
  isGenderProfileSelected: null,
  isGeolocalizationProfileSelected: null,
  isRfmSelected:{
    recency:null,
    frequency:null,
    money:null,
  },
  isCollectionSelected: null,
  isTransacionsSelected:{
    purchaseQuantity:{
      status:false,
      min: 20,
      max:40
    },
    amountPurchase:{
      status:false,
      min: 20,
      max:40
    },
    amountAverage:{
      status:false,
      min: 20,
      max:40
    }
  }

}

const mutations ={

  setCollection(state, payload){
    state.isCollectionSelected = payload
  },
  resetCollection(state){
    state.isCollectionSelected = null
  },
  selectChannels(state, payload){
    state.isChannelSelected = payload
  },
  resetChannels(state){
    state.isChannelSelected = null
  },
  selectDates(state, payload){
    state.isDatesSelected = payload
    console.log(state)
  },
  selectGenderProfile(state, payload){
    state.isGenderProfileSelected = payload
  },
  resetGenderProfile(state){
    state.isGenderProfileSelected = null
  },
  selectGeolocalizationProfile(state, payload){
    state.isGeolocalizationProfileSelected = payload
  },
  resetGeolocalizationProfile(state){
    state.isGeolocalizationProfileSelected = null
  },
}

const actions = {

  setCollection({commit}, payload){
    commit('setCollection', payload)
  },
  resetCollection({commit}){
    commit('resetCollection')
  },
  selectChannels({commit}, payload){
    commit('selectChannels', payload)
  },
  resetChannels({commit}){
    commit('resetChannels')
  },
  selectDates({commit}, payload){
    commit('selectDates', payload)
  },
  selectGenderProfile({commit}, payload){
   commit('selectGenderProfile', payload)
  },
  resetGenderProfile({commit}){
   commit('resetGenderProfile')
  },
  selectGeolocalizationProfile({commit}, payload){
   commit('selectGeolocalizationProfile', payload)
  },
  resetGeolocalizationProfile({commit}){
   commit('resetGeolocalizationProfile')
  },
}

export const BasicSegment = {
  namespaced : true,
  state,
  mutations,
  actions
}
