<template>
  <div v-if="onlyLoader">
    <svg :class="[width, height]" class="text-white bg-green-500 rounded-full p-2 app-spinner" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
      <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
    </svg>
  </div>
  <div v-else class="flex flex-col justify-center items-center">
    <div class="w-full flex flex-col items-center justify-center">
      <div class="inline-flex items-center">
        <svg class="text-white bg-green-500 rounded-full w-10 h-10 p-2 app-spinner" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
          <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
        </svg>
        <span class="text-gray-700 font-semibold ml-3 text-lg">
          {{ title }}
        </span>
      </div>
    </div>
    <div class="w-full inline-flex items-center justify-center mt-3">
      <p class="font-normal text-gray-600">{{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    width: {
      type: String,
      default: function () {
        return "w-10"
      }
    },
    height: {
      type: String,
      default: function () {
        return "h-10"
      }
    },
    onlyLoader: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    title: {
      type: String,
      default: function () {
        return "SetUp"
      }
    },
    text: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped>
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
}
.loader {
  color: #10b981;
  font-size: 11px;
  text-indent: -99999em;
  margin: 55px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before,
.loader:after {
  position: absolute;
  content: "";
}
.loader:before {
  width: 5.2em;
  height: 10.2em;
  background: #f9fafb;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.1em 5.1em;
  transform-origin: 5.1em 5.1em;
  -webkit-animation: load2 2s infinite ease 1.5s;
  animation: load2 3s infinite ease 1.5s;
}
.loader:after {
  width: 5.2em;
  height: 10.2em;
  background: #f9fafb;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 4.9em;
  -webkit-transform-origin: 0.1em 5.1em;
  transform-origin: 0.1em 5.1em;
  -webkit-animation: load2 2s infinite ease;
  animation: load2 2s infinite ease;
}
@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
