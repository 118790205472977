// import { UserServices } from '@/services/User'

const state = {
  user: {},
  showAdminNav: false
}

const mutations = {
  LoadingUser(state) {
    state.loading = true
  },
  getAdminNav(state) {
    state.showAdminNav = true
  },
  getClientNav(state){
    state.showAdminNav = false
  }
}

const actions = {
  async getUser() {},
  getAdminNav({ commit }){
    commit('getAdminNav')
  },
  getClientNav({ commit }){
    commit('getClientNav')
  },
}

export const User = {
  namespaced: true,
  state,
  actions,
  mutations
}
