import axiosClient from "@/config/AxiosClient"
import AuthToken from "@/config/AuthToken"

export const ContentServices = {
  getContent(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    config.params = payload.filter
    const { currentProject } = payload
    return axiosClient.get(`api/1.0/projects/${currentProject}/content`, config)
  },
  getItemContent(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    const { id } = payload
    const { source } = payload
    const { currentProject } = payload
    return axiosClient.get(`api/1.0/projects/${currentProject}/content/show/${source}/${id}`, config)
  },
  getContentKeys(currentProject) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    return axiosClient.get(`api/1.0/projects/${currentProject}/content/keys`, config)
  },
  storeCollection(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    const { collectionSaved } = payload
    const { currentProject } = payload
    return axiosClient.post(`/api/1.0/projects/${currentProject}/collection`, collectionSaved, config)
  },
  updateCollection(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    const { collectionSaved } = payload
    const { currentProject } = payload
    const { id } = payload
    return axiosClient.put(`/api/1.0/projects/${currentProject}/collection/${id}`, collectionSaved, config)
  },
  deleteCollection(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    const { id } = payload
    const { currentProject } = payload
    return axiosClient.delete(`/api/1.0/projects/${currentProject}/collection/${id}`, config)
  }
}
