import axiosClient from "@/config/AxiosClient"
import AuthToken from "@/config/AuthToken"
import Axios from "axios"
let cancelSource = Axios.CancelToken.source()
let cancelToken = cancelSource.token

export const DashboardServices = {
  requestCancelToken() {
    cancelSource.cancel("Request cancelled")
  },
  resetCancelToken() {
    cancelSource = Axios.CancelToken.source()
    cancelToken = cancelSource.token
  },
  dashboardTimeLine(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    config.params = payload.timeline
    const { currentProject } = payload

    config.cancelToken = cancelToken
    return axiosClient.get(`api/1.0/projects/${currentProject}/dashboard/timeline`, config)
  },
  salesChart(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    const { currentProject } = payload
    config.params = payload.params

    return axiosClient.get(`api/2.0/projects/${currentProject}/sales/stats`, config)
  },
  getMetrics(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    config.params = payload.filter
    const { currentProject } = payload

    config.cancelToken = cancelToken
    return axiosClient.get(`/api/2.0/projects/${currentProject}/profiles/metrics`, config)
  },
  getPopularProducts(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    config.params = payload.filter
    const { currentProject } = payload

    config.cancelToken = cancelToken
    return axiosClient.get(`/api/2.0/projects/${currentProject}/reports/products/popular`, config)
  },
  getRelevantProducts(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    config.params = payload.filter
    const { currentProject } = payload

    config.cancelToken = cancelToken
    return axiosClient.get(`/api/2.0/projects/${currentProject}/reports/products/relevant`, config)
  },
  getRelevantCategories(payload) {
    const Token = localStorage.getItem("Token")
    const config = AuthToken(Token)
    config.params = payload.filter
    const { currentProject } = payload

    config.cancelToken = cancelToken
    return axiosClient.get(`/api/2.0/projects/${currentProject}/reports/categories/popular`, config)
  }
}
