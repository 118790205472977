import { DashboardServices } from "@/services/Dashboard"
import Axios from "axios"

const state = {
  loading: false,
  dashboardActive: false,
  timeLineChart: {
    timeline: null,
    onlineTimeline: null,
    offlineTimeline: null,
    dateRange: null
  },
  salesChart: {
    total: null,
    online: null,
    offline: null,
    date: null
  },

  onlineMetrics: null,
  offlineMetrics: null,

  mostPopularProducts: [],
  mostPopularProductsError: false,
  isLoadingMostPopularProducts: false,

  isLoadingRfm: false,

  mostRelevantProducts: [],
  mostRelevantProductsError: false,
  isLoadingMostRelevantProducts: false,

  mostRelevantCategories: [],
  mostRelevantCategoriesError: false,
  isLoadingMostRelevantCategories: false,

  cancelToken: []
}

const mutations = {
  loading(state) {
    state.loading = true
  },
  loadingFalse(state) {
    state.loading = false
  },
  dashboardTimeLine(state, timeLine) {
    state.timeLineChart.timeLine = timeLine
    state.timeLineChart.onlineTimeline = Object.values(timeLine.data.online)
    state.timeLineChart.offlineTimeline = Object.values(timeLine.data.offline)
    state.timeLineChart.dateRange = Object.keys(timeLine.data.online)
    state.loading = false
    state.dashboardActive = true
    // console.log(state)
  },
  salesChart(state, data) {
    state.salesChart.online = data.map((f) => f.online)
    state.salesChart.offline = data.map((f) => f.offline)
    state.salesChart.date = data.map((f) => f.year + "-" + f.month)
  },
  resetTimeLine(state) {
    state.timeLine = null
    state.loading = false
    // state.dashboardActive= false
  },
  getOnlineData(state, data) {
    state.onlineMetrics = data
  },
  getOfflineData(state, data) {
    state.offlineMetrics = data
  },
  resetOnlineData(state) {
    state.onlineMetrics = {}
  },
  resetOfflineData(state) {
    state.offlineMetrics = {}
  },
  getPopularProducts(state, data) {
    state.mostPopularProducts = data
  },
  getRelevantProducts(state, data) {
    state.mostRelevantProducts = data
  },
  getRelevantCategories(state, data) {
    state.mostRelevantCategories = data
  },
  popularProductsError(state) {
    state.mostPopularProductsError = true
  },
  relevantProductsError(state) {
    state.mostRelevantProductsError = true
  },
  relevantCategoriesError(state) {
    state.mostRelevantCategoriesError = true
  },
  resetPopularProducts(state) {
    state.mostPopularProducts = []
    state.mostPopularProductsError = false
  },
  resetRelevantProducts(state) {
    state.mostRelevantProducts = []
    state.mostRelevantProductsError = false
  },
  resetRelevantCategories(state) {
    state.mostRelevantCategories = []
    state.mostRelevantCategoriesError = false
  },
  setCancelToken(state, token) {
    state.cancelToken = [...state.cancelToken, token]
  },
  resetCancelToken(state) {
    state.cancelToken = []
  }
}

const actions = {
  setCancelToken({ commit }, source) {
    commit("setCancelToken", source)
  },
  async dashboardTimeLine({ commit }, payload) {
    commit("resetTimeLine")
    commit("loading")
    let result = null
    try {
      result = await DashboardServices.dashboardTimeLine(payload)
      commit("dashboardTimeLine", result.data)
    } catch (error) {
      Axios.isCancel(error) ? (result = error) : (result = error.response)
    }
    return result
  },
  async salesChart({ commit }, payload) {
    commit("loading")
    let result = null
    try {
      result = await DashboardServices.salesChart(payload)
      commit("salesChart", result.data)
    } catch (error) {
      Axios.isCancel(error) ? (result = error) : (result = error.response)
    }
    return result
  },
  async getOnlineData({ commit }, payload) {
    commit("resetOnlineData")
    let result = null
    try {
      result = await DashboardServices.getMetrics(payload)
      commit("getOnlineData", result.data)
    } catch (error) {
      Axios.isCancel(error) ? (result = error) : (result = error.response)
    }
    return result
  },
  async getOfflineData({ commit }, payload) {
    commit("resetOfflineData")
    let result = null
    try {
      result = await DashboardServices.getMetrics(payload)
      commit("getOfflineData", result.data)
    } catch (error) {
      Axios.isCancel(error) ? (result = error) : (result = error.response)
    }
    // console.log(result)
    return result
  },
  async getPopularProducts({ commit, state }, payload) {
    state.isLoadingMostPopularProducts = true
    commit("resetPopularProducts")
    let result = null
    try {
      result = await DashboardServices.getPopularProducts(payload)
      commit("getPopularProducts", result.data)
    } catch (error) {
      Axios.isCancel(error) ? (result = error) : (result = error.response)
      commit("popularProductsError")
    }
    state.isLoadingMostPopularProducts = false
    // console.log(result)
    return result
  },
  async getRelevantProducts({ commit, state }, payload) {
    state.isLoadingMostRelevantProducts = true
    commit("resetRelevantProducts")
    let result = null
    try {
      result = await DashboardServices.getRelevantProducts(payload)
      commit("getRelevantProducts", result.data)
    } catch (error) {
      Axios.isCancel(error) ? (result = error) : (result = error.response)
      commit("RelevantProductsError")
    }
    state.isLoadingMostRelevantProducts = false
    // console.log(result)
    return result
  },
  async getRelevantCategories({ commit, state }, payload) {
    state.isLoadingMostRelevantCategories = true
    commit("resetRelevantCategories")
    let result = null
    try {
      result = await DashboardServices.getRelevantCategories(payload)
      commit("getRelevantCategories", result.data)
    } catch (error) {
      Axios.isCancel(error) ? (result = error) : (result = error.response)
      commit("relevantCategoriesError")
    }
    state.isLoadingMostRelevantCategories = false
    // console.log(result)
    return result
  }
}

export const Dashboard = {
  namespaced: true,
  state,
  mutations,
  actions
}
